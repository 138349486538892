.tabbed-details-block {
  @extend .d-flex;
  width: 100%;
  background-color: white;

  .check-wrapper {
    padding: 0 1.5rem;

    .custom-checkbox {
      position: relative;
      top: 50px;
    }
  }
  
  .user-info {
    padding: 1.5rem 0;
    min-width: 290px;
    border-inline-end: 4px solid #EBEBEB;

    .personal-details {
      @extend .d-flex;
      @extend .align-items-center;

      .avatar {
        flex: none;
        width: 64px;
        height: 64px;
        margin-inline-end: 1rem;
      }

      .details-wrapper {
        p {
          color: #63666A;
          margin: 0;
        }

        .title {
          font-weight: 900;
          font-size: 18px;
          color: #212121;
          margin-bottom: 0.25rem;
        }
      }
    }

    .meta {
      margin-top: 2rem;

      .status {
        font-weight: 500;

        .icon {
          margin-inline-end: 0.5rem;
        }

        .icon,
        .label {
          vertical-align: middle;
        }
      }
    }
  }

  .tabs {
    @extend .d-flex;
    @extend .flex-column;

    flex: 1;

    .tab-list {
      background: #EBEBEB;

      .item {
        display: inline-block;
        background: #EBEBEB;
        padding: 13px 15px;
        height: 44px;
        font-weight: 900;
        font-size: 14px;
        color: #212121;
        
        &,&:hover {
          text-decoration: none;
        }

        span {
          display: inline-block;
        }

        &.active {
          background: white;

          span {
            border-bottom: 4px solid #CA100B;
            padding-bottom: 3px
          }
        }
      }
    }

    .tab-content {
      @extend .d-flex;

      .details {
        flex: 1;
        padding: 10px 15px;

        .item {
          padding: 6px 0;
        }

        p {
          margin: 0;
        }

        .label {
          font-size: 14px;
          color: #888B8D;
        }

        .value {

        }
      }

      .actions {
        @extend .d-flex;

        .action {
          @extend .d-flex;
          @extend .align-items-center;
          @extend .justify-content-center;

          padding: 10px;
          border: 0;
          outline: none;

          &.action-primary {
            background: $primary;
            color: white;
          }

          &.action-secondary {
            background: $secondary;
            color: white;
          }

          &.action-muted {
            background: #D8D8D8;
            color: #212121;
          }
        }

        .dropdown-menu {
          top: 85%!important;
          margin-inline-end: 10px!important;
        }
      }
    }
  }

  &.block-muted {
    background-color: #EBEBEB;

    .tabs {
      .tab-list {
        background: #D8D8D8;

        .item {
          background: #D8D8D8;

          &.active {
            background-color: #EBEBEB;
          }
        }
      }
    }
  }

  &.block-selected {
    background-color: #EDEDF8;

    .tabs {
      .tab-list {
        background: #D3D3E3;

        .item {
          background: #D3D3E3;

          &.active {
            background-color: #EDEDF8;
          }
        }
      }
    }
  }
}