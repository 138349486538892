.toast-container {
  width: 100%;
  right: 0;
  top: 0;
  font-size: 18px;

  .ngx-toastr {
    width: 100%;
    padding: 11px 40px;
  }

  @include media-breakpoint-down(xs) {
    display: none!important;
  }
}

.toast-success,
.toast-info,
.toast-warning,
.toast-error {
  border: none;
  background-image: none;
  color: white;
}

.toast-info {
  background: $primary;
}

.toast-error {
  background: $secondary;
}