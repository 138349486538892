ul {
  padding-inline-start: 1rem;
}

.ul-color ul,
ul.ul-color {
  list-style: none;
  padding-inline-start: 1.2rem;

  li {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    
    &:before {
      content: "\2022";
      font-weight: bold;
      display: inline-block; 
      width: 1.2em;
    }
  }
}

li.list-header {
  background-color: #EBEBEB;
}


//
// LTR
//

html:not([dir=rtl]) {
  .ul-color ul,
  ul.ul-color {
    li {
      &:before {
        margin-left: -1.2em;
      }
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .ul-color ul,
  ul.ul-color {
    li {
      &:before {
        margin-right: -1.2em;
      }
    }
  }
}
