.filters-group {
  .divider {
    display: inline-block;
    margin: 0 1rem;
    border-right: 1px solid #D8D8D8;
    height: 1.5rem;
  }

  .btn-link {
    font-size: 14px;
    padding-left: 2px;
    padding-right: 2px;
    line-height: 1;
  }

  .btn-link,
  .custom-scope-range,
  .custom-filter-checks-dropdown,
  .ng-select,
  .form-group.styled,
  .divider {
    vertical-align: middle;
  }

  .ng-select, 
  .form-group.styled {
    display: inline-block;
    margin-top: -24px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 150px;
  }

  .ng-select {
    .ng-placeholder {
      transform: translateY(8px);
    }

    .ng-select-container {
      background: transparent;

      .ng-value-container {
        .ng-value,
        .ng-input,
        .ng-select-multiple-value {
          padding-inline-start: 0;
        }
      }

      &.ng-has-value {
        .ng-value-container {
          .ng-input {
            // max-width: 50px;
          }
        }
      }
    }
  }

  .form-group.styled {
    .form-control {
      width: auto;
      background: transparent;
      padding-inline-start: 0!important;

      & ~ label {
        left: 0!important;
      }
    }

    &.floating-label {
      .form-control {
        &:placeholder-shown {
          & ~ label {
            top: 35px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .ng-select, 
    .form-group.styled {
      width: 175px;
    }
  }

  @include media-breakpoint-up(xl) {
    .ng-select, 
    .form-group.styled {
      width: 200px;
    }
  }
}



// LTR
//

html:not([dir=rtl]) {
  .filters-group { 
    .ng-select {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            left: 0;
          }
        }
      }
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .filters-group { 
    .ng-select {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            right: 0;
          }
        }
      }
    }
  }
}
