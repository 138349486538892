.table-styled table,
table.table-styled {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;

  th, td {
    padding: 0.7rem 1rem;
  }

  thead {
    th {
      background-color: $primary;
      color: white;
      font-weight: 900;
      font-size: 1.125rem;
    }
  }

  tbody {
    tr:nth-child(even) {
      background: #C8D7FF;
    }

    ul, ol {
      color: $body-color;
      font-weight: 400;
    }

    ul, ol, p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}