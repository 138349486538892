.profile-picture {
  position: relative;
  width: 144px!important;
  height: 144px!important;
  background: #EBEBEB;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 44px;
    height: 44px;
  }

  &.has-value {
    svg {
      color: white;
    }
  }
}

.control-profile-picture {
  position: relative;
  margin: 0 0 1rem 0;

  .profile-picture {
    cursor: pointer;
  }

  .clear {
    -webkit-appearance: none;
    border: 0;
    padding: 4px 8px;
    line-height: 1;
    position: absolute;
    top: -5px;
    left: 125px;
    background: transparent;
    outline: none;
  }

  &.disabled {
    .profile-picture {
      cursor: none;
      pointer-events: none;
    }
  }
}