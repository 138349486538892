$default-layout-header-patient-height: 286px;
$default-layout-header-patient-height-md: 293px;
$default-layout-header-patient-height-lg: 300px;

$default-layout-header-patient-height-shrunk: 226px;
$default-layout-header-patient-height-shrunk-md: 233px;
$default-layout-header-patient-height-shrunk-lg: 240px;

.default-layout {
  .layout-header.header-patient,
  .layout-header-pusher.header-patient {
    box-sizing: content-box;

    height: $default-layout-header-patient-height;

    transition: height 0.3s;

    @include media-breakpoint-up(md) {
      height: $default-layout-header-patient-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-patient-height-lg;
    }

    &.header-shrunk {
      height: $default-layout-header-patient-height-shrunk;

      @include media-breakpoint-up(md) {
        height: $default-layout-header-patient-height-shrunk-md;
      }

      @include media-breakpoint-up(lg) {
        height: $default-layout-header-patient-height-shrunk-lg;
      }
    }
  }

  .layout-header.header-patient {
    .header-content {
      padding-top: 8px;
      padding-bottom: 0;
    }

    .action-tabs-bar {
      width: auto;
      border-bottom: 0;

      margin-inline-start: -24px;
      margin-inline-end: -24px;

      @include media-breakpoint-up(md) {
        margin-inline-start: -32px;
        margin-inline-end: -32px;
      }
    
      @include media-breakpoint-up(lg) {
        margin-inline-start: -40px;
        margin-inline-end: -40px;
      }
    }
  }
}