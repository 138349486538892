.heading-block {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  background: #EBEBEB;
  min-height: 72px;

  .block-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem 1rem;

    h1, h2, h3,
    p {
      margin: 0;
    }

    p {
      @extend .text-muted;
    }
  }

  .block-action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    
    min-width: 72px;
    padding: 0 1rem;
    min-height: 72px;
    background: #D8D8D8;
    text-align: center;
    color: $body-color;

    svg {
      display: block;   
    }
  }
  a.block-action {
    font-weight: 500;
    text-decoration: none;

    &:hover {
      background: #d3d3e4;
      color: $primary;
      text-decoration: none;
    }
  }

  .progress-bar-wrapper {
    width: 227px;
  }
}