ul.breadcrumbs {
  list-style: none;
  padding-left: 0;

  li {
    font-weight: 400;
    color: #63666A;
    font-size: 14px;
    display: inline-block;
    line-height: 18px;

    a {
      color: #63666A;
      line-height: 18px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
