.toggle-switch {
  display: inline-block;
  width: auto;
  cursor: pointer;
  position: relative;

  .outer {
    border: 3px #D8D8D8 solid;
    background-color: #D8D8D8;
    border-radius: 20px;
    display: flex;
    padding: 3px;
    height: 40px;
  }

  .label {
    padding-top: 2px;
  }

  .label-on {
    padding-left: 8px;
    padding-right: 4px;
  }

  .label-off {
    padding-left: 4px;
    padding-right: 8px;
    opacity: 1;
  }

  .label-on {
    opacity: 0;
    color: #000099;
  }

  .dot {
    width: 28px;
    height: 28px;
    background-color: #888B8D;
    position: absolute;
    border-radius: 50%;
    right: auto;
    left: 6px;
  }

  &.active {
    .outer {
      border: 3px #000099 solid;
      background-color: white;
    }

    .label-off {
      opacity: 0;
    }

    .label-on {
      opacity: 1;
    }

    .dot {
      background-color: #000099;
      right: 6px;
      left: auto;
    }
  }
}
