html {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
}

html[dir=rtl] {
  direction: rtl;
  
  body {
    text-align: right;
  }
}

svg {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  width: 24px;
  height: 24px;

  .icon-lg > & {
    width: 32px;
    height: 32px;
  }

  .large-svg > & {
    width: 178px;
    height: 178px;
  }
}
