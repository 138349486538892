.page-loader {
  text-align: center;
  
  svg {
    display: block;
    width: 40px;
    height: 40px;
  }

  padding: 40px 0;

  @include media-breakpoint-up(md) {
    padding: 60px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 80px 0;
  }
}