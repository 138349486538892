body .optanon-alert-box-wrapper   {
  background-color: #63666A!important;

  button {
    outline: none;
  }

  .optanon-alert-box-button-middle {

    &.accept-cookie-container {
      background-color: transparent!important;
      border-width: 2px!important;
      padding: 0!important;

      button {
        padding: 8px 18px;
        border-color: white!important;

        &, &:hover, &:focus {
          background-color: transparent!important;
        }

        &:hover, &:focus {
          background-color: white!important;
          color: #63666A!important;
        }
      }
    }
    
    button.cookie-settings-button {
      background-color: transparent!important;
      border-width: 0px!important;
      color: white!important;

      &::before {
        position: relative;
        left: 0;
        top: 0;
        margin-inline-end: 3px;
        color: white!important;
      }

      &, &:hover, &:focus {
        background-color: transparent!important;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}