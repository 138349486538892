html[dir=rtl] {
  .rtl-d-none {
    display: none!important;
  }

  .rtl-d-block {
    display: block!important; 
  }

  .rtl-d-inline {
    display: inline!important; 
  }

  .rtl-d-inline-block {
    display: inline-block!important;
  }

  .text-align-start {
    text-align: right;
  }

  .text-align-end {
    text-align: left;
  }

  .rtl-mirrored,
  .rtl-mirrored-inline-svg svg {
    transform: scaleX(-1);
  }
}

html:not([dir=rtl]) {
  .text-align-start {
    text-align: left;
  }

  .text-align-end {
    text-align: right;
  }
}


@for $i from 0 through 10 {
  html:not([dir=rtl]) {
    .m-start-#{$i} {
      margin-left: #{$spacer_base*$i}!important;
    }

    .m-end-#{$i} {
      margin-right: #{$spacer_base*$i}!important;
    }

    .m-start-auto {
      margin-left: auto;
    }

    .m-end-auto {
      margin-right: auto;
    }

    .p-start-#{$i} {
      padding-left: #{$spacer_base*$i}!important;
    }

    .p-end-#{$i} {
      padding-right: #{$spacer_base*$i}!important;
    }

    @include media-breakpoint-up(md) {
      .m-start-md-#{$i} {
        margin-left: #{$spacer_base*$i}!important;
      }

      .m-end-md-#{$i} {
        margin-right: #{$spacer_base*$i}!important;
      }

      .m-start-md-auto {
        margin-left: auto;
      }

      .m-end-md-auto {
        margin-right: auto;
      }

      .p-start-md-#{$i} {
        padding-left: #{$spacer_base*$i}!important;
      }

      .p-end-md-#{$i} {
        padding-right: #{$spacer_base*$i}!important;
      }
    }

    @include media-breakpoint-up(lg) {
      .m-start-lg-#{$i} {
        margin-left: #{$spacer_base*$i}!important;
      }

      .m-end-lg-#{$i} {
        margin-right: #{$spacer_base*$i}!important;
      }

      .m-start-lg-auto {
        margin-left: auto;
      }

      .m-end-lg-auto {
        margin-right: auto;
      }
      
      .p-start-lg-#{$i} {
        padding-left: #{$spacer_base*$i}!important;
      }

      .p-end-lg-#{$i} {
        padding-right: #{$spacer_base*$i}!important;
      }
    }

    @include media-breakpoint-up(xl) {
      .m-start-xl-#{$i} {
        margin-left: #{$spacer_base*$i}!important;
      }

      .m-end-xl-#{$i} {
        margin-right: #{$spacer_base*$i}!important;
      }

      .m-start-xl-auto {
        margin-left: auto;
      }

      .m-end-xl-auto {
        margin-right: auto;
      }

      .p-start-xl-#{$i} {
        padding-left: #{$spacer_base*$i}!important;
      }

      .p-end-xl-#{$i} {
        padding-right: #{$spacer_base*$i}!important;
      }
    }
  }

  html[dir=rtl] {
    .m-start-#{$i} {
      margin-right: #{$spacer_base*$i}!important;
    }

    .m-end-#{$i} {
      margin-left: #{$spacer_base*$i}!important;
    }

    .m-start-auto {
      margin-right: auto;
    }

    .m-end-auto {
      margin-left: auto;
    }

    .p-start-#{$i} {
      padding-right: #{$spacer_base*$i}!important;
    }

    .p-end-#{$i} {
      padding-left: #{$spacer_base*$i}!important;
    }

    @include media-breakpoint-up(md) {
      .m-start-md-#{$i} {
        margin-right: #{$spacer_base*$i}!important;
      }

      .m-end-md-#{$i} {
        margin-left: #{$spacer_base*$i}!important;
      }

      .m-start-md-auto {
        margin-right: auto;
      }

      .m-end-md-auto {
        margin-left: auto;
      }

      .p-start-md-#{$i} {
        padding-right: #{$spacer_base*$i}!important;
      }

      .p-end-md-#{$i} {
        padding-left: #{$spacer_base*$i}!important;
      }
    }

    @include media-breakpoint-up(lg) {
      .m-start-lg-#{$i} {
        margin-right: #{$spacer_base*$i}!important;
      }

      .m-end-lg-#{$i} {
        margin-left: #{$spacer_base*$i}!important;
      }

      .m-start-lg-auto {
        margin-right: auto;
      }

      .m-end-lg-auto {
        margin-left: auto;
      }

      .p-start-md-#{$i} {
        padding-right: #{$spacer_base*$i}!important;
      }

      .p-end-md-#{$i} {
        padding-left: #{$spacer_base*$i}!important;
      }
    }

    @include media-breakpoint-up(xl) {
      .m-start-xl-#{$i} {
        margin-right: #{$spacer_base*$i}!important;
      }

      .m-end-xl-#{$i} {
        margin-left: #{$spacer_base*$i}!important;
      }

      .m-start-xl-auto {
        margin-right: auto;
      }

      .m-end-xl-auto {
        margin-left: auto;
      }

      .p-start-xl-#{$i} {
        padding-right: #{$spacer_base*$i}!important;
      }

      .p-end-xl-#{$i} {
        padding-left: #{$spacer_base*$i}!important;
      }
    }
  }
}