.text-icon-link {
  transition: color .2s ease-in-out;

  .label,
  .icon-start,
  .icon-end {
    display: inline-block;
    vertical-align: middle;
  }

  .label {
    font-weight: 500;
  }

  .icon-end,
  .icon-start {
    position: relative;
  }

  .icon-start {
    margin-inline-end: 0.5rem;
  }

  .icon-end {
    margin-inline-start: 0.5rem;
  }

  &.text-primary {
    &:hover {
      .badge-element {
        background-color: #00004d;
      }
    }
  }

  &.text-secondary {
    &:hover {
      .badge-element {
        background-color: #b41b08;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

a.text-icon-link {
  text-decoration: none;
  color: white;
}