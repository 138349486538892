.nav-item {
  display: block;

  .nav-link {
    display: flex;
    align-items: center;
    color: $body-color;
    width: 100%;

    padding-block-start: 8px;
    padding-block-end: 8px;
    padding-inline-start: 10px;
    padding-inline-end: 24px;

    @media screen and ( min-height: 790px ) {
      padding-block-start: 10px;    
      padding-block-end: 10px;
    }

    @media screen and ( min-height: 840px ) {
      padding-block-start: 12px;    
      padding-block-end: 12px;
    }

    .icon {
      flex: none;
      padding: 10px;
      border-radius: 44px;
      transition: background-color 0.6s;
      width: 44px;
      height: 44px;

      svg {
        display: block;
        width: 24px;
        height: 24px;
      }
    }

    .label {
      margin: 0 16px;
      font-size: 18px;
      line-height: 36px;
      font-weight: 500;
    }

    &:hover {
      .icon {
        background-color: rgba($primary, 0.1);
      }
    }
  }

  .nav-badge {
    @extend .badge-element;
  }

  &.active {
    .nav-link {
      color: $primary;

      .icon {
        background-color: $primary;
        color: white;
      }

      .label {
        font-weight: 900;
      }
    }
  }

  &.has-subnav {
    .chevron {
      rotate: 180deg;
      transition: rotate .1s ease-in-out;
    }

    &.is-collapsed {
      .chevron {
        rotate: 0deg;
      }
    }
  }
}

.nav-item-subnav {
  .subnav-link {
    display: block;
    width: 100%;
    padding: 8px;
    padding-inline-start: 70px;
    font-size: 16px;
    font-weight: $font-weight-medium; 
    color: $body-color;
    transition: background-color 0.6s;

    &:hover {
      text-decoration: none;
      background-color: rgba($primary, 0.1);
    }

    &.active {
      font-weight: $font-weight-bold;
      color: $primary;
      background-color: rgba($primary, 0.15);
    }
  }
}


//
// LTR
//

html:not([dir=rtl]) {
  
}


//
// RTL
//

html[dir=rtl] {
  
}
