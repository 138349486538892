$default-layout-header-title-only-height: 82px;
$default-layout-header-title-only-height-md: 90.5px;
$default-layout-header-title-only-height-lg: 98px;

.default-layout {
  .layout-header.header-title-only,
  .layout-header-pusher.header-title-only {
    box-sizing: content-box;
    height: $default-layout-header-title-only-height;

    .header-content {
      h1, h2, h3 {
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      height: $default-layout-header-title-only-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-title-only-height-lg;
    }
  }
}
