$default-layout-sidebar-collapsed-width: 64px;
$default-layout-sidebar-open-width: 375px;
$default-layout-sidebar-width-lg: 375px;
$default-layout-sidebar-width-xl: 416px;

.default-layout {
  position: relative;
  padding-inline-start: $default-layout-sidebar-collapsed-width;

  @include media-breakpoint-up(lg) {
    padding-inline-start: $default-layout-sidebar-width-lg;
  }

  @include media-breakpoint-up(xl) {
    padding-inline-start: $default-layout-sidebar-width-xl;
  }

  .layout-header {
    background: white;
    border-bottom: 2px solid #E6E6E6;
    z-index: 50;
    position: fixed;
    top: 0;
  }

  .header-overlap-block {
    position: relative;
    background: white;

    .header-content {
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
    }

    &:after {
      content: '';
      width: 100%;
      height: 20px;
      display: block;

      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.1+0,0+100 */
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a000000', endColorstr='#00000000', GradientType=0); /* IE6-9 */

      background-repeat: repeat-x;
      background-size: auto 100%;

      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  .layout-sidebar {
    position: fixed;
    height: 100%;
    background: white;
    top: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: $default-layout-sidebar-collapsed-width;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
    z-index: 100;

    @include media-breakpoint-down(md) {
      transition: width 0.3s, box-shadow 0.2s;
    }

    @include media-breakpoint-up(lg) {
      width: $default-layout-sidebar-width-lg;
    }

    @include media-breakpoint-up(xl) {
      width: $default-layout-sidebar-width-xl;
    }

    .sidebar-content {
      padding: 0;
      width: $default-layout-sidebar-open-width;

      @include media-breakpoint-up(lg) {
        width: 100% !important;
      }
    }
  }

  .layout-body {
    background: #F4F4F4;
  }

  .layout-footer {
    position: fixed;
    bottom: 0;
    left: $default-layout-sidebar-collapsed-width;
    background: white;
    box-shadow: 0 0 36px rgba(136, 136, 141, 0.3);
  }

  .layout-footer,
  .layout-footer-pusher {
    height: 64px;
  }

  &.open {
    @include media-breakpoint-down(md) {
      .layout-sidebar {
        width: $default-layout-sidebar-open-width;
      }
    }
  }
}


//
// Backdrop
//

.default-layout-backdrop {
  background: rgba(33, 33, 33, 0);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;
  display: none;
  transition: background 0.15s;

  &.visible {
    display: block;

    &.shown {
      background: rgba(33, 33, 33, 0.3);
    }
  }

  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}

//
// App Layout Headers
//

@import "headers/header-tabs";
@import "headers/header-filters";
@import "headers/header-back";
@import "headers/header-back-tabs";
@import "headers/header-back-tabs-progress";
@import "headers/header-back-filters";
@import "headers/header-title-only";
@import "headers/header-conversation";
@import "headers/header-patient";
@import "headers/header-single-link";

//
// App Layout Footers
//

@import "footers/footer-conversation";

//
// App Layout Conversations
//

@import "conversations/middle-conversation";

//
// LTR
//

html:not([dir=rtl]) {
  .default-layout {
    .layout-header,
    .layout-footer {
      right: 0;
      left: $default-layout-sidebar-collapsed-width;

      @include media-breakpoint-up(md) {
        left: $default-layout-sidebar-collapsed-width;
      }

      @include media-breakpoint-up(lg) {
        left: $default-layout-sidebar-width-lg;
      }

      @include media-breakpoint-up(xl) {
        left: $default-layout-sidebar-width-xl;
      }
    }

    .layout-sidebar {
      border-right: 2px solid #E6E6E6;
      left: 0;
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .default-layout {
    .layout-header,
    .layout-footer {
      left: 0;
      right: $default-layout-sidebar-collapsed-width;

      @include media-breakpoint-up(md) {
        right: $default-layout-sidebar-collapsed-width;
      }

      @include media-breakpoint-up(lg) {
        right: $default-layout-sidebar-width-lg;
      }

      @include media-breakpoint-up(xl) {
        right: $default-layout-sidebar-width-xl;
      }
    }

    .layout-sidebar {
      border-left: 2px solid #E6E6E6;
      right: 0;
    }
  }
}
