.ng-select {
  padding-bottom: 0;

  .ng-spinner-loader {
    position: absolute!important;
    top: 50%;
    // margin-top: -7px;
  }

  .ng-select-container {
    background: $input-bg;
    min-height: 54px;

    &:after {
      border-bottom: 2px solid #212121!important;
    }

    .ng-value-container {
      border-top-width: 10px;

      .ng-placeholder {
        transition: transform 0.2s, color 0.2s, font-size 0.2s;
        font-size: $font-size-base*0.875;
        color: #63666A;
      }

      .ng-input {
        input {
          width: calc(100% - 16px)!important;
        }
      }

      .ng-value,
      .ng-input,
      .ng-select-multiple-value {
        padding-inline-start: 8px;
      }
    }

    .ng-clear-wrapper,
    .ng-arrow-wrapper {
      .ng-arrow,
      .ng-clear {
        color: $dark!important;
      }
    }

    .ng-arrow-wrapper {
      padding-inline-end: 8px;

      .ng-arrow {
        margin: 0px 4px 3px 4px;
      }
    }
  }

  .ng-select-multiple-value {
    flex: 3;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.ng-select-multiple.ng-select-focused.ng-select-opened{
    .ng-select-multiple-value {
      display: none;
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-arrow-wrapper {
        bottom: 0;
        top: 5px
      }
    }

    &.ng-select-opened .ng-select-container .ng-arrow-wrapper,
    &.ng-select-focused .ng-select-container .ng-arrow-wrapper,
    .ng-select-container.ng-has-value .ng-arrow-wrapper {
      top: 0;
    }
  }

  &.ng-select-opened,
  &.ng-select-focused,
  .ng-select-container.ng-has-value {
    .ng-value-container {
      border-top-width: 16px!important;

      .ng-placeholder {
        font-size: $font-size-base*0.75;
        transform: translateY(-1.28125em) scale(1)!important;
        color: #63666A!important;
      }
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      background: $input-disabled-bg;

      &:after {
        border-bottom-color: transparent!important;
        background-image: none;
      }

      .ng-value-container {
        .ng-value {
          color: #63666A;
        }
      }

      .ng-arrow-wrapper {
        opacity: 0.3;
      }

      .ng-clear-wrapper {
        display: none;
      }
    }

  }

  &.plain-select {
    display: inline-block;
    vertical-align: middle;

    .ng-select-container {
      min-height: 0;

      .ng-value-container {
        margin: 0;
        border: 0;
        padding: .5em 0;
        font-weight: 500;
      }

      &:after {
        border: 0!important;
      }

      .ng-arrow-wrapper .ng-arrow {
        margin-bottom: 0px;
      }
    }

    .ng-dropdown-panel {
      width: auto;
    }
  }

  &.ng-select-as-filter {
    .ng-select-container {
      background: transparent;
    }
  }
}

//
// dropdown panel
//
.ng-dropdown-panel {
  min-width: 100%;

  &.ng-select-bottom {
    top: 100%;
  }

  &.ng-select-top {
    bottom: 100%;
  }

  .ng-dropdown-panel-items {
    .ng-option {
      &.ng-option-selected {
        color: $dark;
      }
    }
  }
}

// Works only in Mozilla Firefox browser
@supports (-moz-appearance:none) {
  .ff-overflow-scroll .ng-dropdown-panel-items {
    overflow-y: scroll!important;
  }
}

//
// required astriks
//
.required {
  .ng-select {
    .ng-placeholder {
      &:after {
        content: ' *';
        color: $secondary;
      }
    }
  }
}

//
// validation
//
.validation-visible {
  .ng-select.ng-invalid,
  app-select.ng-invalid .ng-select {
    .ng-select-container {
      &:after {
        border-bottom-color: $secondary!important;
      }

      .ng-value-container {
        .ng-placeholder {
          color: $secondary!important;
        }
      }
    }
  }
}

//
// LTR
//

html:not([dir=rtl]) {
  .ng-select {
    .ng-spinner-loader {
      right: 30px!important;
      left: auto!important;
    }

    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          left: 8px;
        }
      }
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .ng-select {
    .ng-spinner-loader {
      left: 30px!important;
      right: auto!important;
    }

    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          right: 8px;
        }
      }
    }

    .ng-dropdown-panel {
      left: auto;
      right: 0;
    }
  }
}
