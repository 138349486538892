.form-width-limit {
  max-width: 310px;

  @include media-breakpoint-up(lg) {
    max-width: 360px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 390px;
  }
}