.label-element {
  display: inline-block;
  background: #00A3AD;
  font-size: 14px;
  padding: 3px 8px;
  color: white;
  
  margin-top: 0;
  margin-inline-start: 0;
  margin-inline-end: 8px;
  margin-bottom: 8px;

  &.small {
    font-weight: bold;
    font-size: 12px;
  }

  &:last-child {
    margin-inline-end: 0;
    margin-bottom: 0;
  }
}