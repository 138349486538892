$default-layout-header-back-tabs-progress-height: 188px;
$default-layout-header-back-tabs-progress-height-md: 195px;
$default-layout-header-back-tabs-progress-height-lg: 202px;

.default-layout {
  .layout-header.header-back-tabs-progress,
  .layout-header-pusher.header-back-tabs-progress {
    box-sizing: content-box;

    height: $default-layout-header-back-tabs-progress-height;

    .header-content {
      padding-top: 8px;
      padding-bottom: 0;
    }

    .wide-progress-bar-wrapper {
      border-top: 2px solid #F4F4F4;
      width: auto;

      margin-inline-start: -24px;
      margin-inline-end: -24px;
      padding-left: 24px;
      padding-right: 24px;

      @include media-breakpoint-up(md) {
        margin-inline-start: -32px;
        margin-inline-end: -32px;
        padding-left: 32px;
        padding-right: 32px;
      }
    
      @include media-breakpoint-up(lg) {
        margin-inline-start: -40px;
        margin-inline-end: -40px;
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    @include media-breakpoint-up(md) {
      height: $default-layout-header-back-tabs-progress-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-back-tabs-progress-height-lg;
    }
  }
}