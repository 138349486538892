.custom-scope-range {
  display: inline-block;

  button, span {
    vertical-align: middle;
    display: inline-block;
  }

  button {
    border: 0;
    background: 0;
    padding: 0;

    span {
      display: block;
    }

    &:hover,
    &:focus {
      outline: none;
    }
  }

  .label {
    font-weight: 900;
    font-size: 1.124rem;
    padding: 0px 0.3rem;
  }
}