.custom-filter-checks-dropdown {
  position: relative;
  display: inline-block;
  border: 0;
  background: none;
  padding: 0;

  .label {
    font-weight: 500;
    font-size: 14px;
    margin-inline-end: 8px;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  .dropdown-modal {
    display: none;
    position: absolute;
    top: 100%;
    background-color: white;
    box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.2);
    padding: 1rem;
    min-width: 320px;
    text-align: left;
  }

  &:focus {
    .dropdown-modal {
      display: block;
    }
  }
}