.display-1 {
  font-size: 40px;
  font-weight: 900;

  svg {
    width: 53px;
    height: 53px;
  }

  span, .icon {
    vertical-align: middle;
  }

  @include media-breakpoint-up(md) {
    font-size: 46px;

    svg {
      width: 62px;
      height: 62px;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 52px;

    svg {
      width: 71px;
      height: 71px;
    }
  }

  @include media-breakpoint-up(xl) {
    font-size: 60px;

    svg {
      width: 80px;
      height: 80px;
    }
  }
}

.h1, h1,
.h2, h2,
.h3, h3 {
  svg {
    width: 32px;
    height: 32px;
    @include media-breakpoint-up(md) {
      width: 40px;
      height: 40px;
    }
  }
}

.h1, h1 {
  font-size: 28px;

  @include media-breakpoint-up(md) {
    font-size: 34px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }
}

.h2, h2 {
  font-size: 24px;

  @include media-breakpoint-up(md) {
    font-size: 26px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
}

.h3, h3 {
  font-size: 17px;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

//
// LTR
//

html:not([dir=rtl]) {
  .h1, h1,
  .h2, h2,
  .h3, h3 {
    svg {
      margin-right: 0.5rem;
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .h1, h1,
  .h2, h2,
  .h3, h3 {
    svg {
      margin-left: 0.5rem;
    }
  }
}

.highlight {
  color: #CC0099;
  font-weight: 700;
}
