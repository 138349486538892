.default-layout .layout-footer.layout-footer.footer-conversation .notice, // support deprecated class
.footer-notice {
  padding: 0 24px;
  flex: none;
  min-height: 0;
  overflow-y: initial;
  background: #F0DCEB;
  color: #CC0099;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
}