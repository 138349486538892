.page-tabs {
  display: block;
  position: relative;

  .scroll {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .inline-nav {
    position: relative;
    transition: left 0.3s;
  }

  .arrow-left,
  .arrow-right {
    width: 80px;
    height: 100%;
    position: absolute;
    top: 0;
    transition: opacity 0.3s;

    &:not(.visible) {
      pointer-events: none;
      opacity: 0;
    }

    &.visible {
      opacity: 1;
    }
  }

  .arrow-left {
    left: 0;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0.85+80,0+100 */
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.85) 80%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.85) 80%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.85) 80%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

    background-size: 100% auto;
    background-repeat: repeat-y;
  }

  .arrow-right {
    text-align: right;
    right: 0;        
    
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.85+20,1+100 */
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 20%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.85) 20%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.85) 20%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

    background-size: 100% auto;
    background-repeat: repeat-y;
  }
}