.sidebar-content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 24px;
  flex: 1;
  overflow-x: hidden;

  .sidebar-logo {
    svg {
      display: block;
      width: 190px;
      height: 28px;
      margin: 0 0 1rem 0;

      @include media-breakpoint-up(lg) {
        width: 230px;
        height: 32px;
      }
    }
  }

  .sidebar-title {
    color: #63666A;
    font-weight: 900;
    font-size: 14px;
    padding-inline-start: 10px;
    padding-inline-end: 24px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .sidebar-open-element-visible,
  .sidebar-open-element-block,
  .sidebar-open-element-inline-block {
    display: none;
  }

  .content-part {
    @include media-breakpoint-up(sm) {
      padding-bottom: 24px;
    }

    @media screen and ( min-height: 850px ) and ( min-width: 992px ){
      padding-bottom: 32px;
    }

    @media screen and ( min-height: 1000px )and ( min-width: 992px ){
      padding-bottom: 40px;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &.scrollable-part {
      overflow: auto;
    }
  }

  .footer-part {
    @media screen and ( min-height: 1000px ){
      margin-top: auto;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 32px;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px;

    .sidebar-open-element-visible,
    .sidebar-open-element-block {
      display: block;
    }

    .sidebar-open-element-inline-block {
      display: inline-block;
    }
  }  

  &.is-flexed {
    display: flex;
    flex-direction: column;
  }
}

.default-layout.open {
  .layout-sidebar {
    .sidebar-content {
      .sidebar-open-element-visible,
      .sidebar-open-element-block {
        display: block;
      }

      .sidebar-open-element-inline-block {
        display: inline-block;
      }
    }
  }
}
