.inline-nav {
  padding: 0;
  margin: 0;
  line-height: 1.2;

  &.no-wrap {
    display: inline;
    white-space: nowrap;
  }

  li {
    display: inline-block;
    margin: 0;
    margin-inline-end: 2rem;
    
    @include media-breakpoint-up(md) {  
      margin-inline-end: 2rem;
    }

    @include media-breakpoint-up(lg) {  
      margin-inline-end: 2.5rem;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-block;
      font-size: 16px;
      line-height: 20px;
      color: $body-color;
      padding-bottom: 2px;
      border-bottom: 4px solid transparent;
      transition: border 0.4s;
      font-weight: 500;

      @include media-breakpoint-up(md) {  
        padding-bottom: 3px;
      }

      @include media-breakpoint-up(lg) {  
        padding-bottom: 4px;
      }

      &:hover {
        text-decoration: none;
        color: $secondary;
      }
    }

    &.active {
      a {
        border-color: $secondary;
        font-weight: 900;
      }
    }
  } 
}