.pagination-container {
  background-color: white;
  display: flex;
  padding: 16px 32px;
  max-width: 1410px;
  margin: auto;
}

.pagination-component button {
  transition: .15s all ease-in-out;
  text-align: center;
  margin: 6px;

  padding-left: 8px;
  padding-right: 8px;

  border-bottom: 3px solid transparent;

  &,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover:not(.disabled) {
    color: #CA001B;
    background-color: #EBEBEB;
  }

  &.pages {
    &.current {
      color: #CA001B;
      border-bottom-color: #CA001B;
    }

    &.ignore {
      pointer-events: none;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.disabled {
    opacity: 0.35;
    pointer-events: none;
  }
}
