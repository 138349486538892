.img-icon,
.img-icon-xs,
.img-icon-sm,
.img-icon-md,
.img-icon-lg,
.img-icon-xl {
  &,
  img {
    display: block;
    object-fit: contain;
  }  
}

.img-icon-xs {
  width: 32px; 
  height: 32px; 
}

.img-icon-sm {
  width: 48px; 
  height: 48px; 
}

.img-icon,
.img-icon-md {
  width: 64px;
  height: 64px;
}

.img-icon-lg {
  width: 80px;
  height: 80px;
}

.img-icon-xl {
  width: 96px;
  height: 96px;
}