typeahead-container {
  width: 100%;
  margin-top: -1px!important;

  .dropdown-item {
    border-bottom: 1px solid #D8D8D8;

    &:last-child {
      border-bottom: 0;
    }
  }
}