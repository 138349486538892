@font-face {
  font-family: "CircularStd";
  src: url("../../assets/fonts/circular/circular-black-italic.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/circular/circular-black-italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../assets/fonts/circular/circular-black-italic.otf") format("opentype"), /* Open Type Font */
    url("../../assets/fonts/circular/circular-black-italic.svg") format("svg"), /* Legacy iOS */
    url("../../assets/fonts/circular/circular-black-italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../assets/fonts/circular/circular-black-italic.woff") format("woff"), /* Modern Browsers */
    url("../../assets/fonts/circular/circular-black-italic.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 900;
  font-style: italic;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "CircularStd";
  src: url("../../assets/fonts/circular/circular-black.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/circular/circular-black.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../assets/fonts/circular/circular-black.otf") format("opentype"), /* Open Type Font */
    url("../../assets/fonts/circular/circular-black.svg") format("svg"), /* Legacy iOS */
    url("../../assets/fonts/circular/circular-black.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../assets/fonts/circular/circular-black.woff") format("woff"), /* Modern Browsers */
    url("../../assets/fonts/circular/circular-black.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 900;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "CircularStd";
  src: url("../../assets/fonts/circular/circular-bold-italic.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/circular/circular-bold-italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../assets/fonts/circular/circular-bold-italic.otf") format("opentype"), /* Open Type Font */
    url("../../assets/fonts/circular/circular-bold-italic.svg") format("svg"), /* Legacy iOS */
    url("../../assets/fonts/circular/circular-bold-italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../assets/fonts/circular/circular-bold-italic.woff") format("woff"), /* Modern Browsers */
    url("../../assets/fonts/circular/circular-bold-italic.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "CircularStd";
  src: url("../../assets/fonts/circular/circular-bold.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/circular/circular-bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../assets/fonts/circular/circular-bold.otf") format("opentype"), /* Open Type Font */
    url("../../assets/fonts/circular/circular-bold.svg") format("svg"), /* Legacy iOS */
    url("../../assets/fonts/circular/circular-bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../assets/fonts/circular/circular-bold.woff") format("woff"), /* Modern Browsers */
    url("../../assets/fonts/circular/circular-bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "CircularStd";
  src: url("../../assets/fonts/circular/circular-book-italic.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/circular/circular-book-italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../assets/fonts/circular/circular-book-italic.otf") format("opentype"), /* Open Type Font */
    url("../../assets/fonts/circular/circular-book-italic.svg") format("svg"), /* Legacy iOS */
    url("../../assets/fonts/circular/circular-book-italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../assets/fonts/circular/circular-book-italic.woff") format("woff"), /* Modern Browsers */
    url("../../assets/fonts/circular/circular-book-italic.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: italic;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "CircularStd";
  src: url("../../assets/fonts/circular/circular-book.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/circular/circular-book.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../assets/fonts/circular/circular-book.otf") format("opentype"), /* Open Type Font */
    url("../../assets/fonts/circular/circular-book.svg") format("svg"), /* Legacy iOS */
    url("../../assets/fonts/circular/circular-book.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../assets/fonts/circular/circular-book.woff") format("woff"), /* Modern Browsers */
    url("../../assets/fonts/circular/circular-book.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "CircularStd";
  src: url("../../assets/fonts/circular/circular-medium-italic.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/circular/circular-medium-italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../assets/fonts/circular/circular-medium-italic.otf") format("opentype"), /* Open Type Font */
    url("../../assets/fonts/circular/circular-medium-italic.svg") format("svg"), /* Legacy iOS */
    url("../../assets/fonts/circular/circular-medium-italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../assets/fonts/circular/circular-medium-italic.woff") format("woff"), /* Modern Browsers */
    url("../../assets/fonts/circular/circular-medium-italic.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: italic;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "CircularStd";
  src: url("../../assets/fonts/circular/circular-medium.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/circular/circular-medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../assets/fonts/circular/circular-medium.otf") format("opentype"), /* Open Type Font */
    url("../../assets/fonts/circular/circular-medium.svg") format("svg"), /* Legacy iOS */
    url("../../assets/fonts/circular/circular-medium.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../assets/fonts/circular/circular-medium.woff") format("woff"), /* Modern Browsers */
    url("../../assets/fonts/circular/circular-medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}