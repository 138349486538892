.custom-filter-input {
  position: relative;
  display: inline-block;

  input {
    border: 0;
    background: none;
    padding: 0;
    font-size: 14px;
    border-bottom: 2px solid #212121;
    height: 29px;

    &:focus {
      outline: none;
      box-shadow: none;
      background: none;
      border-color: #212121;
    }
  }
}