label {
  color: #63666A;

  &.required {
    &:after {
      content: '*';
      margin: 0 4px;
      color: #CA001B;
    }
  }

  &.tiny {
    font-size: 0.75rem;
  }
}