.bs-datepicker {
  .bs-datepicker-container {
    padding: 0;
  }

  .bs-datepicker-head {
    background: white;

    button {
      color: $dark;
      
      &,
      span {
        border-radius: 0;
      }
    }
  }

  .bs-datepicker-body {
    border-radius: 0;
    border: 0;
    border-top: 2px solid #F3F3F3;
  }

  table {
    thead th:first-child,
    tbody td.week {
      display: none;
    }

    th {
      font-size: 10px;
      color: $dark;
      text-transform: uppercase;
    }

    td {
      color: $dark;
      span {
        border-radius: 0!important;
      }
    }
  }
}