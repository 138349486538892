.notice {
  display: flex;
  flex-direction: column;
  background: #F4F4F4;
  z-index: 9999;
  overflow-y: auto;
  padding: 24px;
  min-height: 100vh;

  @include media-breakpoint-up(md) {
    padding: 32px;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px;
  }

  &.notice-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 0;
  }

  h2, h3 {
    max-width: 570px;
  }

  p {
    max-width: 410px;
  }

  .logo {
    svg {
      height: auto;
      width: 164px;
    }
  }
}