.custom-radio {
  .custom-control-input{
    &:checked {
      & ~ .custom-control-label {
        font-weight: 500;
      }
    }

    &:disabled {
      &:checked {
        & ~ .custom-control-label {
          &::before {
            border-color: rgba(0, 0, 0, 0.06);
          }
        }
      }
    }
  }
}