.empty-state {
  text-align: center;
  padding: 0 24px;
  margin: auto;
  width: 100%;

  max-width: 470px;

  svg {
    width: 120px;
    height: 120px;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      width: 150px;
      height: 150px;
    }

    // @include media-breakpoint-up(lg) {
    //   width: 175px;
    //   height: 175px;
    // }
  }
}