.environment-select-modal {
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      margin-bottom: 8px;
      position: relative;

      &:last-child{
        margin-bottom: 0;
      }
    }

    .background-color {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
      opacity: .1;
    }

    a {
      z-index: 1;
      display: block;
      padding: 16px;
      position: relative;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .logo {
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    margin-right: 16px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2, h3 {
    color: white;
    margin-bottom: 0;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
  }

  h3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    opacity: .8;
  }
}
