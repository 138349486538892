$default-layout-header-back-height: 96px;
$default-layout-header-back-height-md: 103px;
$default-layout-header-back-height-lg: 110px;

.default-layout {
  .layout-header.header-back,
  .layout-header-pusher.header-back {
    box-sizing: content-box;

    height: $default-layout-header-back-height;

    .header-content {
      padding-top: 8px;

      h1 {
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      height: $default-layout-header-back-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-back-height-lg;
    }
  }
}