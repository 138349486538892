a.single-item-block {
  &, &:hover, &:focus {
    text-decoration: none;
  }
}

.single-item-block {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: #FFFFFF;

  .block-wrapper,
  .block-checkbox {
    @extend .d-flex;
    @extend .justify-content-center;
    flex-direction: column;
  }

  .block-checkbox {
    padding: 1rem;
    padding-inline-end: 0;
    min-width: 40px;

    .custom-control {
      margin: 0;
    }
  }
  
  .block-wrapper {
    padding: 1rem;

    .avatar {
      width: 40px;
      height: 40px;

      &.avatar-lg {
        width: 56px;
        height: 56px;

        @include media-breakpoint-up(lg) {
          width: 64px;
          height: 64px;
        }
      }
    }

    h1, h2, h3,
    p {
      margin: 0;
    }

    p {
      color: #888B8D;
    }

    .label {
      font-size: 18px;
      font-weight: 900;
      color: $body-color;
    }

    .value {
      font-size: 14px;
      font-weight: 500; 
    }

    &.with-hover {
      &,
      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:hover,
      &:focus {
        background: rgba($danger, .1);
      }
    }
  }

  .block-action-wrapper {
    flex: none;
    @extend .d-flex;
  }

  .block-action {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    flex: none;
    
    min-width: 56px;
    padding: 0 1rem;
    min-height: 72px;
    background: $secondary;
    text-align: center;
    color: #FFFFFF;

    svg {
      display: block;   
    }

    &.action-compact {
      min-width: 56px;
    }

    &.action-light {
      background: #FFFFFF;
      color: $secondary;
    }

    &.action-muted {
      background: #D8D8D8;
      color: $body-color;
    }

    &.action-close {
      background: #FFFFFF;
      color: #888B8D;
      padding: 0.6rem 1rem;
      
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  
  &.item-clickable {
    cursor: pointer;
    outline: none;
  }

  &.item-clickable .block-action,
  a.block-action {
    font-weight: 500;
    outline: none;
  }

  &.item-clickable:hover .block-action,
  a.block-action:hover {
    background: rgba($secondary, 0.7);

    &.action-light {
      background: rgba($secondary, 0.07);
    }

    &.action-muted {
      background: #e5e5e5;
    } 
  }

  &.item-clickable:hover .block-action {
    &.action-close {
      background: #FFFFFF;
      color: #888B8D;
    }
  }

  a.block-action:hover {
    &.action-close {
      background: #FFFFFF!important;
      color: $secondary!important;
    }
  }

  &.block-muted {
    background: #EBEBEB;
  }

  &.block-danger {
    background: rgba($secondary, 0.1);
  }

  &.block-info {
    background: #F9E8F3;
  }

  &.block-selected {
    background: rgba(0, 0, 153, 0.1);

    .block-action.action-muted {
      background: #C2C2D2;
    }

    &.item-clickable:hover .block-action.action-muted,
    a.block-action.action-muted:hover {
      background: #cccce1;
    }
  }

  &.with-shadow {
    box-shadow: 0 0 10px 5px rgba(0,0,0,0.13);
  }
}