//
// Colors
//

$gray: #D8D8D8;
$gray-dark: #63666A;



//
// Body 
//

$body-bg: $poly-colors-action-primary-nd-50-existing;
$body-color: $color-text-body;


//
// Theme Colors
//

$primary: $color-text-highlighted;
$secondary: $color-action-primary-enabled;
$danger: $color-text-error;
$info: $color-text-highlighted;
$light: $poly-colors-grey-050-existing;
$dark: $body-color;


//
// Links
//

$link-color: $info;


//
// Options
//

$enable-responsive-font-sizes: true;


//
// Spacing
//

$spacer: 1rem;
$spacers: ();

$spacer_base: calc($spacer/2); //8

$spacers: map-merge(
  (
    0: 0,
    1: $spacer_base*1,
    2: $spacer_base*2,
    3: $spacer_base*3,
    4: $spacer_base*4,
    5: $spacer_base*5,
    6: $spacer_base*6,
    7: $spacer_base*7,
    8: $spacer_base*8,
    9: $spacer_base*9,
    10: $spacer_base*10,
    11: $spacer_base*11,
    12: $spacer_base*12,
    13: $spacer_base*13,
    14: $spacer_base*14,
    15: $spacer_base*15,
    16: $spacer_base*16,
    17: $spacer_base*17,
    18: $spacer_base*18,
    19: $spacer_base*19,
    20: $spacer_base*20
  ),
  $spacers
);


//
// Grid breakpoints
// 

$grid-breakpoints: (
  xxxs: 0,
  xxs: 576px,
  xs: 768px,
  sm: 992px,
  md: 1220px,
  lg: 1440px,
  xl: 1710px
);


//
// Grid containers
//

$container-max-widths: (
  xxs: 540px,
  xs: 720px,
  sm: 1410px
);


//
// Grid columns
//

$grid-gutter-width: 16px;


//
// Components
//

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;


//
// Typography
//

$font-size-base: 1rem;
$font-family-base: 'CircularStd';

// $h1-font-size: $font-size-base * 2.857;
// $h2-font-size: $font-size-base * 2;
// $h3-font-size: $font-size-base * 1.286;

$headings-color: #212121;
$headings-font-weight: 900;
$headings-line-height: 1.25;
$headings-margin-bottom: 1rem;

$display1-size: $font-size-base * 4.285;
$display1-weight: 900;
$small-font-size: 87.5%;

$font-weight-bolder: 900;
$font-weight-medium: 500;

$text-muted: $color-text-disabled;

$hr-border-color: rgba(#D8D8D8, 0.5);
$hr-border-width: 2px;
$hr-margin-y: 1.5rem;


// 
// Buttons + Forms
// 

$input-btn-padding-x: 1.25rem;
$input-btn-font-size-sm: $font-size-base;


// 
// Buttons 
// 

$btn-font-weight: 500;

$btn-padding-y: 0.43rem;

$btn-padding-y-sm: 0.2rem;
$btn-padding-x-sm: 1rem;


//
// Forms
//

$input-bg: rgba(0, 0, 0, 0.03);
$input-disabled-bg: rgba(0, 0, 0, 0.06);
$input-focus-border-color: $secondary;

$custom-control-spacer-x: 1.5rem;
$custom-control-label-color: $body-color;
$custom-control-indicator-border-width: 2px;
$custom-control-indicator-checked-bg:$secondary;
$custom-control-indicator-active-bg: $secondary;
$custom-control-indicator-border-color: #D8D8D8;
$custom-control-indicator-disabled-bg: white;
$custom-control-indicator-checked-disabled-bg: #D8D8D8;
$custom-radio-indicator-icon-checked: none;

$custom-control-indicator-focus-box-shadow: 0 0 0 3px rgba($custom-control-indicator-checked-bg,0.2);

$custom-checkbox-indicator-icon-checked: none;


// 
// Dropdowns 
// 

$dropdown-link-active-color: $body-color;
$dropdown-link-active-bg: #D8D8D8;


//
// Cards
//

$card-border-width: 0;
$card-spacer-x: 1rem;
$card-cap-bg: white;


// 
// Progress bars
// 

$progress-height: 12px;
$progress-bg: #D8D8D8;
$progress-border-radius: 6px;
$progress-box-shadow: none;


// 
// Modals
// 

$modal-content-border-width: 0;
$modal-header-padding: 1.5rem;
$modal-inner-padding: 1.5rem;


// 
// Alerts
// 

$alert-bg-level: -11;
$alert-border-level: -10;
$alert-color-level: 0;


//
// Import the bootstrap base from node modules
//

@import "./node_modules/bootstrap/scss/bootstrap";
