.card {
  &,
  &:hover,
  &:focus {
    color: $body-color;
    text-decoration: none;
  }

  .card-icon {
    text-align: center;
    margin: 2.45rem auto 1rem auto;

    svg {
      width: 70px;
      height: 70px;

      @include media-breakpoint-up(lg) {
        width: 80px;
        height: 80px;
      }

      @include media-breakpoint-up(xl) {
        width: 90px;
        height: 90px;
      }
    }
  }

  .card-logo {
    margin: 0 0 1rem 0;
    img {
      display: block;
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }
  }

  .card-cover {
    display: block;
    position: relative;
    width: 100%;
    background-color: grey;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    outline: none;

    &:before {
      content: '';
      display: block;
      padding-top: 56%;
    }

    .cover-meta {
      @extend .d-flex;
      position: absolute;
      top: 0;

      .meta-category {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        background: $primary;
        width: 40px;
        height: 40px;
        color: white;
      }
    }
  }

  .card-footer {
    @extend .d-flex;
    @extend .align-items-center;

    padding-top: 0;
    padding-bottom: 0;
    border-top: 3px solid #F4F4F4;

    p {
      margin: 0;
    }

    .btn {
      @extend .d-flex;
      @extend .align-items-center;

      padding-left: 1rem;
      padding-right: 1rem;
      height: 56px;

    }
  }

  &.card-muted {
    &,
    .card-footer {
      background: #EBEBEB;
    }
  }

  &.card-goal {
    .card-body {
      display: flex;
      flex-direction: column;
    }

    .goal-box {
      padding: 3rem 2em;
      margin: auto;
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 1.875rem;
        color: $primary;
        line-height: 1.2;
        max-width: 100%;

        &,
        .small {
          font-weight: 900;
        }

        .small {
          font-size: 53%;
        }

        &.title {
          font-size: 3.25rem;
          line-height: 1.05;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
  
      &.goal-box-text {
        background: rgba(0, 0, 153, 0.05);
        min-width: 65%;
        max-width: 95%;
      }
      
      &.goal-box-graph {
        padding: 0;
        max-width: 200px;
        width: 80%;
      }
  
    }
  }
}


//
// LTR
//

html:not([dir=rtl]) {
  .card {
    .card-footer {
      padding-right: 0;
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .card {
    .card-footer {
      padding-left: 0;
    }
  }
}