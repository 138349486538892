$default-layout-header-back-tabs-height: 138px;
$default-layout-header-back-tabs-height-md: 145px;
$default-layout-header-back-tabs-height-lg: 152px;

.default-layout {
  .layout-header.header-back-tabs,
  .layout-header-pusher.header-back-tabs {
    box-sizing: content-box;

    height: $default-layout-header-back-tabs-height;

    .header-content {
      padding-top: 8px;
    }

    @include media-breakpoint-up(md) {
      height: $default-layout-header-back-tabs-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-back-tabs-height-lg;
    }
  }
}