$footer-conversation-height: 96px;

.layout-footer.footer-conversation,
.layout-footer-pusher.footer-conversation {
  height: #{$footer-conversation-height};
}

.layout-footer.footer-conversation {
  display: flex;
  flex-direction: column;

  .footer-content {
    height: 100%;
    display: flex;
    padding-inline-start: 24px;
    padding-inline-end: 24px;

    input {
      flex: 1;
      border: 0;
      outline: none;
    }

    .input-submit {
      flex: none;
      color: #797979;
      border: 0;
      outline: none;
      background: transparent;
      transition: color .2s ease-in-out;

      svg {
        width: 32px;
        height: 32px;
      }

      &:hover,
      &:focus {
        cursor: pointer;
      }

      &:disabled,
      &[disabled] {
        color: #D8D8D8;

        &:hover, &:focus {
          cursor: default;
        }
      }
    }
  }
}