.environment-indicator-nagative {
  margin-left: -40px;
  margin-right: -40px;

  .environment-indicator {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.environment-indicator {
  background-color: $primary;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 32px;
  position: relative;
  cursor: pointer;

  h2, h3 {
    color: white;
    margin-bottom: 0;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
  }

  h3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    opacity: .8;
  }

  .logo {
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    margin-right: 16px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chevron-down {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    margin-right: 24px;

    @include media-breakpoint-up(md) {
      margin-left: 32px;
    }
  
    @include media-breakpoint-up(lg) {
      margin-left: 40px;
    }
  }
  
  &.read-only {
    cursor: auto
  }
}
