
$default-layout-header-conversation-height: 140px;
$default-layout-header-conversation-height-md: 144px;
$default-layout-header-conversation-height-lg: 148px;

.default-layout {
  .layout-header.header-conversation,
  .layout-header-pusher.header-conversation {
    height: $default-layout-header-conversation-height;

    .header-content {
      h1, h2, h3 {
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      height: $default-layout-header-conversation-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-conversation-height-lg;
    }
  }
}