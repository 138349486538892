$default-layout-header-content-height: 82px;
$default-layout-header-content-height-md: 90.5px;
$default-layout-header-content-height-lg: 98px;

.header-content {
  padding: 1.5rem 24px;
  height: 100%;
  min-height: $default-layout-header-content-height;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 32px;
    min-height: $default-layout-header-content-height-md;
  }

  @include media-breakpoint-up(lg) {
    padding: 1.5rem 40px;
    min-height: $default-layout-header-content-height-lg;
  }

  h1, h2, h3 {
    line-height: 1.1;
    margin-bottom: 0;
  }

  .title-button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1, h2 {
      padding: 0;
    }

    .btn {
      align-self: center;
    }
  }
}
