.team-member-item {
  @extend .participant-item;
  padding: 1rem;

  .avatar {
    width: 64px;
    height: 64px;
  }

  .meta {
    flex: 1;
    overflow: hidden;

    .title {
      font-weight: 900;
      font-size: 18px;
      line-height: 1;
    }

    .description {
      font-size: 16px;
      font-weight: 500;
      color: #63666A;
    }

    .tag {
      display: inline-block;
      color: #CC0099;
      background: rgba(204, 0, 153, 0.1);
      font-size: 0.875rem;
      padding: 2px 6px;
      margin: 0.3rem 0 0 0;
    }
  }
}