$default-layout-header-single-link-height: 48px;

.default-layout {
  .layout-header.header-single-link,
  .layout-header-pusher.header-single-link {
    box-sizing: content-box;

    height: $default-layout-header-single-link-height;

    .header-content {
      padding-top: 8px;
      padding-bottom: 8px;

      p {
        margin: 0;
      }
    }
  }
}