.custom-control-inline {
  margin-right: 1.5rem;
}

// 
// RTL
// 

html[dir=rtl] {
  .custom-control-inline {
      margin-right: 0;
      margin-left: 1.5rem;
  }
}