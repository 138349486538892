.user-compact {
  display: flex;
  align-items: center;

  .avatar {
    flex: none;
    margin-inline-end: 1rem;
    width: 40px;
    height: 40px;
  }

  .user-compact-info {
    flex: 1;
    overflow: hidden;
    
    p {
      font-size: 0.875rem;
      margin: 0;
      color: #888B8D;
    }

    .title {
      font-weight: bold;
      font-size: 1rem;
      color: $dark;
    }
  }
}

a.user-compact { 
  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  .user-compact-info {
    .title {
      color: $secondary;
    }
  }
}