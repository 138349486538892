$list-width: 400px;

.panes-view {
  padding-inline-start: $list-width;
  
  app-page-loader {
    margin: auto 0;
  }

  .pane-list {
    position: fixed;
    height: 100vh;
    top: 0;
    width: $list-width;
    border-inline-end: 2px solid #E6E6E6;
    display: flex;
    flex-direction: column;

    .layout-header {
      position: relative;
      width: 100%;
      height: $default-layout-header-conversation-height;

      @include media-breakpoint-up(md) {
        height: $default-layout-header-conversation-height-md;
      }
  
      @include media-breakpoint-up(lg) {
        height: $default-layout-header-conversation-height-lg;
      }

      .header-content {
        @include media-breakpoint-up(lg) {
          padding-inline-end: 32px;
        }
      }
    }

    .items {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }
  }

  .pane-detail {
    min-height: 100vh;

    &.empty {
      background: #EBEBEB;
      display: flex;
    }
  }
}

html:not([dir=rtl]) {
  .panes-view {
    .pane-list {
      left: 0;

      .layout-header {
        left: 0!important;
        right: auto!important;
      }
    }
  }

  .pane-detail {
    .layout-header,
    .layout-footer {
      left: calc( #{$default-layout-sidebar-collapsed-width} + #{$list-width} )!important;
      right: 0;

      @include media-breakpoint-up(lg) {
        left: calc( #{$default-layout-sidebar-width-lg} + #{$list-width} )!important;
      }

      @include media-breakpoint-up(xl) {
        left: calc( #{$default-layout-sidebar-width-xl} + #{$list-width} )!important;
      } 
    }
  }

  .default-layout .layout-body {
    .panes-view {
      .pane-list {
        left: $default-layout-sidebar-collapsed-width;
  
        @include media-breakpoint-up(lg) {
          left: $default-layout-sidebar-width-lg;
        }
  
        @include media-breakpoint-up(xl) {
          left: $default-layout-sidebar-width-xl;
        } 
      }
    }
  }
}

html[dir=rtl] {
  .panes-view {
    .pane-list {
      right: 0;

      .layout-header {
        right: 0!important;
        left: auto!important;
      }
    }
  }

  .pane-detail {
    .layout-header,
    .layout-footer {
      right: calc( #{$default-layout-sidebar-collapsed-width} + #{$list-width} )!important;
      left: 0;

      @include media-breakpoint-up(lg) {
        right: calc( #{$default-layout-sidebar-width-lg} + #{$list-width} )!important;
      }

      @include media-breakpoint-up(xl) {
        right: calc( #{$default-layout-sidebar-width-xl} + #{$list-width} )!important;
      } 
    }
  }

  .default-layout .layout-body {
    .panes-view {
      .pane-list {
        right: $default-layout-sidebar-collapsed-width;
  
        @include media-breakpoint-up(lg) {
          right: $default-layout-sidebar-width-lg;
        }
  
        @include media-breakpoint-up(xl) {
          right: $default-layout-sidebar-width-xl;
        } 
      }
    }
  }
}
