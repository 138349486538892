.sidebar-toggle {
  display: flex;
  align-items: center;

  .logo {
    padding: 2rem 0;

    svg {
      display: block;
      width: 190px;
      height: 28px;

      @include media-breakpoint-up(lg) {
        width: 230px;
        height: 32px;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-left: 8px;
      padding-right: 24px;
    }
  }

  .toggle-closed,
  .toggle-open {
    display: block;
    padding: 16px;
    color: $body-color;

    svg {
      width: 32px;
      height: 32px;
    }

    @include media-breakpoint-up(lg) {
      display: none!important;
    }
  }

  .toggle-open {
    display: none;
  }

  .btn-notifications {
    display: block;
    background: rgba($primary, 0.1);
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 100%;
    color: $primary;
    position: relative;

    svg {
      display: block;
      margin: 8px;
    }

    &.new {
      &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: $secondary;
        border-radius: 100%;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &:hover {
      svg {
        opacity: 0.65;
      }
    }
  }
}

.default-layout.open {
  .toggle-open {
    display: block;
  }

  .toggle-closed {
    display: none;
  }
}


//
// LTR
//

html:not([dir=rtl]) {
  .sidebar-toggle {
    padding-right: 14px;

    .btn-notifications {
      margin-left: auto;
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .sidebar-toggle {
    padding-left: 14px;

    .btn-notifications {
      margin-right: auto;
    }
  }
}