.action-bar {
  @extend .d-flex;

  padding: 0.75rem 1rem;
  padding-inline-start: 0.5rem;

  min-height: 64px;
  align-items: center;
  -webkit-align-items: center;
  position: relative;
  background: #F4F4F4;

  .avatar {
    width: 44px;
    height: 44px;
    margin-inline-end: 1rem;
    flex: none;
  }

  .main-context {
    display: block;
    font-size: 18px;
  }

  .sub-context {
    display: block;
    font-size: 14px;
    color: #63666A;
  }

  p,
  .input-wrapper,
  .action-bar-btn {
    color: #000000;
  }

  p,
  .input-wrapper {
    position: relative;
    margin: 0;
  }

  p,
  .input-wrapper .input {
    font-weight: 500;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    max-width: 100%;
    overflow: hidden;


    .input {
      &, span {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      line-height: 1.3;
    }

    svg {
      flex: none;
    }
  }

  a.input-wrapper {
    text-decoration: none;

    &:hover {
      opacity: 0.65;
    }
  }

  .action-bar-btn {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    padding: 4px;
    flex: none;

    &:hover {
      svg {
        opacity: 0.65;
      }
    }

    svg {
      display: block;
      width: 100%;
      height: auto;
    }

    &.closed-state {
      display: none;
    }
  }
  
  &.primary {
    background: rgba($primary, 0.1);
    
    p,
    .input-wrapper,
    .action-bar-btn {
      color: $primary;
    }
  }

  &.secondary {
    background: rgba($secondary, 0.1);

    p,
    .input-wrapper,
    .action-bar-btn {
      color: $secondary;
    }
  }
}

.default-layout {
  .layout-sidebar {
    .sidebar-content {
      .action-bar {
        @include media-breakpoint-down(md) {
          transition: left 0.3s, right 0.3s;

          .action-bar-btn {
            &.open-state {
              display: none;
            }

            &.closed-state {
              display: block;
            }
          }
        }
      }
    }
  }

  &.open {
    .layout-sidebar {
      .sidebar-content {
        .action-bar {
          @include media-breakpoint-down(md) {
            .action-bar-btn {
              &.open-state {
                display: block;
              }

              &.closed-state {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}


//
// LTR
//

html:not([dir=rtl]) {
  .action-bar {
    .input-wrapper {
      padding-right: 1rem;
    }

    .action-bar-btn {
      margin-left: auto;
    }
  }

  .default-layout {
    .layout-sidebar {
      .sidebar-content {
        .action-bar {
          @include media-breakpoint-down(md) {
            left: calc( -100% + 64px );
          }
        }
      }
    }

    &.open {
      .layout-sidebar {
        .sidebar-content {
          .action-bar {
            @include media-breakpoint-down(md) {
              left: 0;  
            }
          }
        }
      }
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .action-bar {
    .input-wrapper {
      padding-left: 1rem;
    }

    .action-bar-btn {
      margin-right: auto;
    }
  }

  .default-layout {
    .layout-sidebar {
      .sidebar-content {
        .action-bar {
          @include media-breakpoint-down(md) {
            right: calc( -100% + 64px );
          }
        }
      }
    }

    &.open {
      .layout-sidebar {
        .sidebar-content {
          .action-bar {
            @include media-breakpoint-down(md) {
              right: 0;  
            }
          }
        }
      }
    }
  }
}