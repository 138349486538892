.pagination-range-component {
  display: flex;
  align-content: center;
  align-items: center;

  label {
    margin-inline-end: 1rem;
    margin-bottom: 0;
    font-size: 14px;
    color: #212121;
  }

  .ng-select {
    min-width: 70px;

    .ng-select-container {
      min-height: 0;

      &,
      &.ng-has-value {
        .ng-value-container {
          border-top-width: 0!important;
        }
      }
    }
  }
}
