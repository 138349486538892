timepicker {
  display: block;
  // background: #f7f7f7;
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 2px solid #292929;
  padding: 0.5rem;
  
  table {
    width: 100%;
    height: 36px;
  }

  .bs-timepicker-field,
  .btn-default {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.9rem;
    vertical-align: top;
  }

  .bs-timepicker-field {
    width: 100%;
    background-color: transparent;
    transition: background-color 0.4s;

    &:focus {
      background-color: rgba(0, 0, 0, 0.03);
      box-shadow: none;
      border-color: #313131;
    }
  }

  .btn-default {
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.03);
  }

  &.with-label {
    padding: 1.45rem 0.5rem 0.2rem 0.5rem;

    table {
      height: 26px;
    }

    .btn-default {
      font-size: 0.7rem;
      font-weight: 500;
    }

    & ~ label {
      position: absolute;
      margin: 0;
      left: 8px;
      top: 6px;
      font-size: 0.75rem;
    }
  }
}

.validation-visible {
  timepicker {
    &.ng-invalid,
    &.ng-invalid-important {
      border-bottom-color: $secondary;

      .bs-timepicker-field {
        &:placeholder,
        &::placeholder {
          color: $secondary;
        }
      }
  
      & ~ label {
        color: $secondary;
      }
    }
  }
}

//
// RTL
//

html[dir=rtl] {
  timepicker {
    &.with-label {
      & ~ label {
        left: auto;
        right: 8px;
      }
    }
  }
}