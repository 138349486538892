.action-item-block {
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-stretch;

  background: #FFFFFF;

  .icon-column {
    width: 80px;
    margin-left: -1rem;
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .block-wrapper {
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
  }
  
  .block-wrapper {
    padding: 1.5rem;
  }

  .block-action {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    flex: none;
    
    min-width: 56px;
    padding: 0 1rem;
    min-height: 72px;
    background: $secondary;
    text-align: center;
    color: #FFFFFF;

    svg {
      display: block;   
    }
  }

  a.block-action {
    font-weight: 500;
    
    &:hover {
      background: rgba($secondary, 0.7);
    }
  }
}