.conversation_items {
  height: calc(100vh - #{$default-layout-header-conversation-height} - #{$footer-conversation-height});
  overflow-y: auto;
  //scroll-behavior: smooth;

  @include media-breakpoint-up(md) {
    height: calc(100vh - #{$default-layout-header-conversation-height-md} - #{$footer-conversation-height});
  }

  @include media-breakpoint-up(lg) {
    height: calc(100vh - #{$default-layout-header-conversation-height-lg} - #{$footer-conversation-height});
  }
}