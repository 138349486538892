$default-layout-header-tabs-height: 106px;
$default-layout-header-tabs-height-md: 113px;
$default-layout-header-tabs-height-lg: 120px;

.default-layout {
  .layout-header.header-tabs,
  .layout-header-pusher.header-tabs {
    box-sizing: content-box;
    height: $default-layout-header-tabs-height;

    @include media-breakpoint-up(md) {
      height: $default-layout-header-tabs-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-tabs-height-lg;
    }
  }
}