$default-layout-header-filters-height: 126px;
$default-layout-header-filters-height-md: 133px;
$default-layout-header-filters-height-lg: 140px;

$default-layout-header-double-filters-height: 168px;
$default-layout-header-double-filters-height-md: 174px;
$default-layout-header-double-filters-height-lg: 181px;

$default-layout-header-filters-breadcrumbs-height: 170px;

.default-layout {
  .layout-header.header-filters,
  .layout-header-pusher.header-filters {
    box-sizing: content-box;
    height: $default-layout-header-filters-height;

    @include media-breakpoint-up(md) {
      height: $default-layout-header-filters-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-filters-height-lg;
    }
  }
  
  .layout-header.header-double-filters,
  .layout-header-pusher.header-double-filters {
    box-sizing: content-box;
    height: $default-layout-header-double-filters-height;

    @include media-breakpoint-up(md) {
      height: $default-layout-header-double-filters-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-double-filters-height-lg;
    }
  }
  
  .layout-header.header-filters-breadcrumbs,
  .layout-header-pusher.header-filters-breadcrumbs {
    box-sizing: content-box;
    height: $default-layout-header-filters-breadcrumbs-height;
  }
  
}
