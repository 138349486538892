.validation-feedback {
  color: $secondary;
  font-size: 0.75rem;
  margin-top: 8px;
  display: none;

  &.warning {
    color: $warning;
  }

  &.md {
    font-size: 0.875rem;
  }

  &.lg {
    font-size: 1rem;
  }
}

.validation-visible {
  .ng-invalid  ~.validation-feedback,
  .ng-invalid  ~app-feedback-field .validation-feedback,
  .validation-feedback.general-feedback,
  .file .validation-feedback {
    display: block;
  }
  

  .validation-group.ng-invalid ~.validation-feedback {
    margin-top: -0.5rem;
  }
}
