.btn-group[dropdown] {
  &.text-dropdown {
    .dropdown-toggle {
      padding-left: 0;
      padding-right: 0;
      background: transparent;
      border: none;
      color: black;
      padding: 0;

      .icon {
        margin-inline-start: 0.5rem;

        svg {
          vertical-align: top;
        }
      }

      & > span {
        vertical-align: middle;
      }
      
      &,
      &:focus {
        box-shadow: none;
      }

      &:after {
        content: '';
        display: none;
      }
    }

    &.text-dropdown-sm {
      .dropdown-toggle {
        font-size: 0.875rem;
      }
    }
  }
}


html[dir=rtl] {
  .dropdown-menu {
    &.dropdown-menu-right {
      left: 0!important;
      right: auto!important;
    }
  }
}

.browser-ie-edge {
  .ng-dropdown-panel .scroll-host {
    overflow-y: scroll!important;
  }
}