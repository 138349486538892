.form-group.styled {
  .form-control {
    border: transparent;
    border-bottom: 2px solid #212121;
    padding: 24px 8px 4px 8px;
    height: auto;
    background: $input-bg;
    font-size: $font-size-base;
    color: #000000;
    box-shadow: none;
    width: 300px;

    & ~ label {
      position: absolute;
      transition: top 0.2s, transform 0.2s, color 0.2s, font-size 0.2s;
      pointer-events: none;
      margin: 0;
      left: 8px;
      top: 6px;
      font-size: $font-size-base*0.75;
    }

    &:disabled {
      background: $input-disabled-bg;
      border-bottom-color: transparent; 
      color: #63666A;
    }

    &::placeholder {
      color: transparent;
    }
  }

  &.placeholder {
    .form-control {
      &::placeholder {
        color: #63666A;
      }
    }
  }

  &.floating-label {
    .form-control {
      &:placeholder-shown {
        padding: 14px 8px 14px 8px;

        & ~ label {
          top: 28px;
          transform: translateY(-50%);
          font-size: $font-size-base*0.875;
          color: #63666A;
        }

        &:focus {
          padding: 24px 8px 4px 8px;

          & ~ label {
            top: 6px;
            font-size: $font-size-base*0.75;
            transform: none;
          }
        }
      }
    }
  }
  
  &.toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    
    .toggle-label {
      color: $body-color;
      flex: 1;
    }
    
    app-toggle-switch  {
      flex: 1;
      text-align: end;  
    }
    
    app-feedback-field {
      flex: 0 0 100%;
      text-align: end;
    }
  }
}

.validation-visible .form-group.styled {
  .form-control {
    &.ng-invalid,
    &.ng-invalid-important {
      border-bottom-color: $secondary;
  
      &,
      &:-webkit-autofill,
      &:-webkit-autofill:hover, 
      &:-webkit-autofill:focus,
      &:-internal-autofill-selected {
        color: $secondary!important;
  
        &:not(:placeholder-shown) {
          -webkit-text-fill-color: $secondary!important
        }
      }
      
      & ~ label {
        color: $secondary;
      }
    }
  }
  
  &.toggle {
    .toggle-label:has(~app-toggle-switch.ng-invalid) {
      color: $danger;  
    }
    
    app-toggle-switch.ng-invalid {
      color: $danger;
    
      .outer {
        border-color: $danger;
    
      }
    }
  }
}

//
// RTL
//

html[dir=rtl] {
  .form-group.styled {
    .form-control {
      & ~ label {
        left: auto;
        right: 8px;
      }
    }
  }
}
