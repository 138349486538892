.folders {
  padding-right: 1rem;

  &,
  ul {
    list-style: none;
  }

  li>ul {
    padding-left: 1.875rem;
  }

  ul>ul,
  .templates {
    padding-left: 1rem;
  }

  .btn,
  .btn:hover,
  a,
  a:hover {
    padding: 0;
    display: inline-block;
    color: $body-color;
    text-decoration: none;
  }
  
  .btn:focus {
    box-shadow: none;
  }

  .chevron-right {
    display: inline-block;
    color: #888B8D;

    &.open {
      transform: rotate(90deg);
    }
  }

  .template-group.active,
  .template.active {
    background-color: $gray;
    transition: all .3s linear;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -50%;
      width: 50%;
      height: 100%;
      background-color: inherit;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -50%;
      width: 50%;
      height: 100%;
      background-color: inherit;
    }
  }
}

.modal-body {
  & .folders {
    font-size: .875rem;
    overflow-x: hidden;
    scroll-behavior: smooth;

    .btn {
      font-size: inherit;
      font-weight: inherit;
    }

    .icon-small svg {
      width: 16px;
      height: 16px;
      margin: .25rem;
    }

    @media screen and (min-height: 1040px) {
      overflow-y: auto;
      max-height: 75vh;

    }
  }
}
