.custom-checkbox {
  .custom-control-input{
    & ~ .custom-control-label {
      font-weight: 500;
    }
  }

  &.checkbox-small {
    min-height: 0;
    font-size: 14px;

    .custom-control-label {
      vertical-align: middle;
      line-height: 14px;
    }

    .custom-control-label {
      &:before,
      &:after {
        top: 0;
      }
    }
  }
}