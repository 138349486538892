.footer-content {
  padding: 1rem 24px;

  .container {
    display: flex;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    padding: 1rem 32px;
  }

  @include media-breakpoint-up(lg) {
    padding: 1rem 40px;
  }
}