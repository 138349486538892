.modal-dialog {
  max-width: 490px;

  &.modal-lg {
    max-width: 615px;
  }

  &.modal-xl {
    max-width: 815px;
  }
  
  &.modal-xxl {
    max-width: calc(100vw - 100px);
    
    @include media-breakpoint-up(lg) {
      max-width: calc(100vw - 300px);
    }
    
    @include media-breakpoint-up(xl) {
      max-width: 1400px;
    }
  }

  button.close {
    outline: none;
  }
}
.modal-header {
  padding-bottom: 0;
}

.modal-content {
  position: relative;
  box-shadow: 0 0 20px 10px rgba(0,0,0,0.15);

  .layout-header-pusher,
  .layout-footer-pusher {
    display: none;
  }

  .layout-header {
    border-bottom: 2px solid #E6E6E6;
    min-height: 5rem;
  }

  .layout-footer {
    background: white;
    box-shadow: 0 0 36px rgba(136, 136, 141, 0.3);
  }

  .header-content,
  .body-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .modal-content-conversation {
    button.close {
      position: relative;
      top: 1.5rem;
      right: 1.5rem;
    }

    .conversation_items {
      height: calc(100vh - #{$default-layout-header-conversation-height} - #{$footer-conversation-height} - 100px);
      scroll-behavior: smooth;
      background: #F4F4F4;
    
      @include media-breakpoint-up(md) {
        height: calc(100vh - #{$default-layout-header-conversation-height-md} - #{$footer-conversation-height} - 100px);
      }
    
      @include media-breakpoint-up(lg) {
        height: calc(100vh - #{$default-layout-header-conversation-height-lg} - #{$footer-conversation-height} - 100px);
      }
    }
  }
}

.modal-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.footer-muted {
    background: #F4F4F4;
  }
}

.modal-compact {
  @extend .modal-dialog-centered;

  .modal-body {
    max-height: calc( 90vh - 86px );
    overflow-y: auto;
  }

  .modal-footer {
    box-shadow: 0px 2px 36px rgba(22, 57, 78, 0.2);
    z-index: 10;
    position: relative;
  }
}


//
// RTL
//

html[dir=rtl] {
  .modal-dialog {
    button.close {
      float: left;
      right: auto;
      left: 1.5rem;
    }
  }
}

.modal + .modal {
  background: rgba(0, 0, 0, 0.6);
}
