//
// Scaling
//

@for $i from 1 through 10 {
  .scale-up-#{$i} {
    transform: scale(#{ 1 + ((calc(1/10))*$i)})!important;
  }
}

.scale-up-third {
  transform: scale(#{ 1 + (calc(1/3))})!important;
}

.scale-up-two-third {
  transform: scale(#{ 1 + (calc(2/3))})!important;
}

.scale-up-double {
  transform: scale(2)!important;
}


//
// Text
//

.text-faded {
  opacity: 0.75;
}
a.text-faded {
  color: white;

  &:hover {
    opacity: 1;
  }
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.font-weight-semibold,
.text-semibold {
  font-weight: 500;
}

.text-capitalize-first::first-letter {
  text-transform: uppercase;
} 


// 
// Show / Hide
// 

@include media-breakpoint-up(sm) {
  .hide-desktop {
    display: none!important;
  }
}

@include media-breakpoint-down(xs) {
  .hide-mobile {
    display: none!important;
  }
}


// 
// Icons
// 

em.icon {
  line-height: 1;
}
