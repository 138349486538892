.action-tabs-bar {
  border-top: 2px solid #E6E6E6;
  border-bottom: 2px solid #E6E6E6;

  width: 100%;

  &, 
  & > .container,
  & > .container-fluid {
    @extend .d-flex;
    @extend .align-items-center;
  }

  .bar-actions {
    flex: none;

    .action {
      @extend .btn;
      @extend .btn-passive;
      font-weight: 900;
      height: 62px;

      &,
      &:focus {
        box-shadow: none!important;
      }
    }
  }

  .bar-tabs {
    flex: 1;
    width: 100%;
    display: block;
    overflow: hidden;
    padding: 0.5rem 1.5rem;

    position: relative;
    top: 3px;
  }
}