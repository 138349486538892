// READ THIS FIRST!!!!
// READ THIS FIRST!!!!
// READ THIS FIRST!!!!

// These colours may only be referenced in _bootstrap.scss.
// If you need to declare a new color somewhere,
// first declare it in in _bootstrap.scss and use that variable.
// DO NOT USE THEM SOMEWHERE ELSE!!!

// These values come from a figma file and should be handled as read-only

// READ THIS FIRST!!!!
// READ THIS FIRST!!!!
// READ THIS FIRST!!!!


$poly-padding-025: 0.125rem;
$poly-padding-075: 0.375rem;
$poly-colors-grey-050-existing: #f3f3f3;
$poly-colors-grey-100-existing: #e6e6e6;
$poly-colors-grey-200-existing: #d3d3d3;
$poly-colors-grey-300-existing: #acacac;
$poly-colors-grey-400-existing: #8a8a8a;
$poly-colors-grey-500-existing: #717171;
$poly-colors-grey-600-existing: #5e5e5e;
$poly-colors-grey-700-existing: #545454;
$poly-colors-grey-800-existing: #454545;
$poly-colors-grey-900-existing: #373737;
$poly-colors-grey-000: #ffffff;
$poly-colors-grey-1000: #222222;
$poly-colors-secondary-electric-blue-100-existing: #e1eaff;
$poly-colors-secondary-electric-blue-200-existing: #a9beff;
$poly-colors-secondary-electric-blue-300-existing: #85a0f5;
$poly-colors-secondary-electric-blue-400-existing: #617de6;
$poly-colors-secondary-electric-blue-500-existing: #4962d3;
$poly-colors-secondary-cyan-100-existing: #cbf3fe;
$poly-colors-secondary-cyan-200-existing: #71d1e8;
$poly-colors-secondary-cyan-300-existing: #00b8d6;
$poly-colors-secondary-cyan-400-existing: #0098b7;
$poly-colors-secondary-cyan-500-existing: #007e99;
$poly-colors-secondary-emerald-100-existing: #dff2d5;
$poly-colors-secondary-emerald-200-existing: #a6cf90;
$poly-colors-secondary-emerald-300-existing: #81b662;
$poly-colors-secondary-emerald-400-existing: #589a28;
$poly-colors-secondary-emerald-500-existing: #3f8200;
$poly-colors-secondary-purple-100-existing: #f4e4ff;
$poly-colors-secondary-purple-200-existing: #d4b0f0;
$poly-colors-secondary-purple-300-existing: #bd8de0;
$poly-colors-secondary-purple-400-existing: #a366cd;
$poly-colors-secondary-purple-500-existing: #8e49b9;
$poly-colors-secondary-magenta-100-existing: #ffe0ea;
$poly-colors-secondary-magenta-200-existing: #f5a7be;
$poly-colors-secondary-magenta-300-existing: #e581a1;
$poly-colors-secondary-magenta-400-existing: #cf5582;
$poly-colors-secondary-magenta-500-existing: #95114f;
$poly-colors-cta-50: #e9f2ff;
$poly-colors-cta-200-existing: #b9d5ff;
$poly-colors-cta-300-existing: #78aeff;
$poly-colors-cta-400-existing: #3888ff;
$poly-colors-cta-500-existing: #0066ff;
$poly-colors-primary-000: #ffffff;
$poly-colors-action-primary-nd-50-existing: #f6f5f4;
$poly-colors-primary-100-existing: #edebe8;
$poly-colors-primary-200-existing: #dad7d2;
$poly-colors-primary-300-existing: #c6c1b9;
$poly-colors-primary-400-existing: #afaaa1;
$poly-colors-primary-500-existing: #9c968b;
$poly-colors-primary-600-existing: #7e766d;
$poly-colors-primary-700-existing: #6b635b;
$poly-colors-primary-800-existing: #4a4540;
$poly-colors-primary-900-existing: #322e2a;
$poly-colors-primary-1000: #24211e;
$poly-padding-000: 0rem;
$poly-padding-050: 0.25rem;
$poly-padding-100: 0.5rem;
$poly-padding-150: 0.75rem;
$poly-padding-200: 1rem;
$poly-padding-250: 1.25rem;
$poly-padding-300: 1.5rem;
$poly-padding-400: 2rem;
$poly-padding-500: 2.5rem;
$poly-padding-600: 3rem;
$poly-padding-800: 4rem;
$poly-padding-1000: 5rem;
$poly-padding-1500: 7.5rem;
$poly-padding-900: 4.5rem;
$poly-padding-700: 3.5rem;
$poly-corner-radius-100: 0.5rem;
$poly-corner-radius-050: 0.25rem;
$poly-corner-radius-150: 0.75rem;
$poly-corner-radius-200: 1rem;
$poly-corner-radius-250: 1.25rem;
$poly-corner-radius-300: 1.5rem;
$poly-border-100: 0.0625rem;
$poly-border-200: 0.125rem;
$poly-effects-blur-000: 0rem;
$poly-effects-blur-100: 0.5rem;
$poly-effects-y-050: 0.25rem;
$poly-effects-x-050: 0.25rem;
$poly-effects-y--025: -0.125rem;
$poly-effects-x-000: 0rem;
$poly-effects-blur-150: 0.75rem;
$poly-effects-y-200: 0.25rem;
$poly-effects-x-200: 1rem;
$poly-effects-blur-200: 1rem;
$poly-effects-blur-500: 2.5rem;
$poly-effects-x--200: -1rem;
$poly-effects-y--200: -1rem;
$poly-effects-y--050: -0.25rem;
$poly-effects-x--050: -0.25rem;
$poly-corner-radius-000: 0rem;
$poly-effects-blur-050: 0.25rem;
$poly-effects-blur-025: 0.125rem;
$poly-effects-y-025: 0.125rem;
$poly-effects-x-025: 0.125rem;
$poly-effects-x-075: 0.375rem;
$poly-effects-y-075: 0.375rem;
$poly-effects-y-100: 0.5rem;
$poly-effects-x-100: 0.5rem;
$poly-effects-blur-075: 0.375rem;
$poly-colors-fade-fade-15: #222222;
$poly-effects-y-000: 0rem;
$poly-corner-radius-025: 0.125rem;
$poly-colors-secondary-amber-100: #ffe3d4;
$poly-colors-secondary-amber-200: #f6ad8d;
$poly-colors-secondary-amber-300: #e6895e;
$poly-colors-secondary-amber-400: #d16022;
$poly-colors-secondary-amber-500: #b04b00;
$poly-colors-fade-fade-00: #222222;
$poly-colors-cta-100-existing: #d9e8ff;
$poly-colors-cta-600: #0055d5;
$poly-colors-cta-700: #004cbf;
$poly-colors-cta-800: #003e9c;
$poly-colors-cta-900: #00337f;
$poly-colors-cta-950: #00245a;
$poly-colors-system-green-100-existing: #a0eb8d;
$poly-colors-system-green-200-existing: #4ad926;
$poly-colors-system-green-300-existing: #34b414;
$poly-colors-system-green-400-existing: #259808;
$poly-colors-system-green-500-existing: #1a8400;
$poly-colors-system-red-100-existing: #fdddd8;
$poly-colors-system-red-200-existing: #fbcfc7;
$poly-colors-system-red-300-existing: #f79a8a;
$poly-colors-system-red-400-existing: #f15437;
$poly-colors-system-red-500-existing: #e23010;
$poly-colors-system-yellow-100-existing: #ffe499;
$poly-colors-system-yellow-400-existing: #d69600;
$poly-colors-system-green-50: #e6ffe0;
$poly-colors-system-green-600: #166e00;
$poly-colors-system-green-700: #146300;
$poly-colors-system-green-800: #105000;
$poly-colors-system-green-900: #0d4100;
$poly-colors-system-green-950: #092d00;
$poly-colors-system-red-50: #ffebeb;
$poly-colors-system-red-600: #bd290e;
$poly-colors-system-red-700: #ab250c;
$poly-colors-system-red-800: #8d1e0a;
$poly-colors-system-red-900: #751908;
$poly-colors-system-red-950: #571306;
$poly-colors-system-yellow-50: #fff2d0;
$poly-colors-system-yellow-200: #ffcf33;
$poly-colors-system-yellow-300: #f0b400;
$poly-colors-system-yellow-500: #b88100;
$poly-colors-system-yellow-600: #855800;
$poly-colors-system-yellow-700: #6d4900;
$poly-colors-system-yellow-800: #583800;
$poly-colors-system-yellow-900: #482e00;
$poly-colors-system-yellow-950: #322000;
$poly-colors-opacity-secondary-00: rgba(#222222, 0);
$poly-colors-opacity-secondary-08: rgba(#222222, 0.08);;
$poly-colors-opacity-secondary-16: rgba(#222222, 0.16);;
$poly-colors-opacity-secondary-20: rgba(#222222, 0.20);;
$poly-colors-opacity-secondary-65: rgba(#222222, 0.65);;
$poly-colors-opacity-secondary-75: rgba(#222222, 0.75);;
$poly-colors-opacity-white-00: rgba(#ffffff, 0);
$poly-colors-opacity-white-08: rgba(#ffffff, 0.08);
$poly-colors-opacity-white-16: rgba(#ffffff, 0.16);
$poly-colors-opacity-white-20: rgba(#ffffff, 0.20);
$poly-colors-opacity-white-50: rgba(#ffffff, 0.50);
$poly-colors-opacity-white-75: rgba(#ffffff, 0.75);
$poly-colors-opacity-cta-00: rgba(#0066ff, 0);
$poly-colors-opacity-cta-08: rgba(#0066ff, 0.08);
$poly-colors-opacity-cta-16: rgba(#0066ff, 0.16);
$poly-colors-opacity-cta-20: rgba(#0066ff, 0.20);
$poly-colors-opacity-cta-50: rgba(#0066ff, 0.50);
$poly-colors-opacity-cta-75: rgba(#0066ff, 0.75);
$poly-corner-radius-400: 2rem;
$poly-border-800: 0.5rem;
$poly-colors-fade-fade-75: #222222;
$poly-corner-radius-125: 0.625rem;
$poly-corner-radius-500: 2.5rem;
$poly-colors-opacity-secondary-06: rgba(#222222, 0.06);
$poly-colors-opacity-secondary-12: rgba(#222222, 0.12);
$poly-colors-opacity-secondary-24: rgba(#222222, 0.24);
$poly-colors-opacity-cta-06: rgba(#0066ff, 0.06);
$poly-colors-opacity-cta-12: rgba(#0066ff, 0.12);
$poly-colors-opacity-cta-24: rgba(#0066ff, 0.24);
$poly-colors-secondary-emerald-050: #f1fdea;
$poly-colors-secondary-emerald-600: #346100;
$poly-colors-secondary-emerald-700: #204a00;
$poly-colors-secondary-emerald-800: #0e3300;
$poly-colors-secondary-emerald-900: #051c00;
$poly-colors-secondary-emerald-950: #010500;
$poly-colors-secondary-cyan-050: #e6fdff;
$poly-colors-secondary-cyan-600: #005f75;
$poly-colors-secondary-cyan-700: #004759;
$poly-colors-secondary-cyan-800: #00303d;
$poly-colors-secondary-cyan-900: #001b25;
$poly-colors-secondary-cyan-950: #00050a;
$poly-colors-secondary-electric-blue-950: #01020f;
$poly-colors-secondary-electric-blue-600: #3145ad;
$poly-colors-secondary-electric-blue-700: #223187;
$poly-colors-secondary-electric-blue-800: #16215d;
$poly-colors-secondary-electric-blue-900: #0a1136;
$poly-colors-secondary-electric-blue-050: #f4f8ff;
$poly-colors-secondary-purple-950: #06010b;
$poly-colors-secondary-purple-600: #6e2c96;
$poly-colors-secondary-purple-700: #541d74;
$poly-colors-secondary-purple-800: #39134f;
$poly-colors-secondary-purple-900: #20092e;
$poly-colors-secondary-purple-050: #fdf4ff;
$poly-colors-secondary-magenta-050: #fff3f7;
$poly-colors-secondary-magenta-950: #0b0003;
$poly-colors-secondary-magenta-600: #95114f;
$poly-colors-secondary-magenta-700: #72023a;
$poly-colors-secondary-magenta-800: #4f0427;
$poly-colors-secondary-magenta-900: #2d0315;
$poly-colors-secondary-amber-050: #fff5ee;
$poly-colors-secondary-amber-950: #0b0100;
$poly-colors-secondary-amber-600: #863500;
$poly-colors-secondary-amber-700: #6a2100;
$poly-colors-secondary-amber-800: #4d1100;
$poly-colors-secondary-amber-900: #2e0600;
$poly-gap-000: 0rem;
$poly-gap-025: 0.125rem;
$poly-gap-050: 0.25rem;
$poly-gap-075: 0.375rem;
$poly-gap-100: 0.5rem;
$poly-gap-150: 0.75rem;
$poly-gap-200: 1rem;
$poly-gap-250: 1.25rem;
$poly-gap-300: 1.5rem;
$poly-gap-400: 2rem;
$poly-gap-500: 2.5rem;
$poly-gap-600: 3rem;
$poly-gap-700: 3.5rem;
$poly-gap-800: 4rem;
$poly-gap-900: 4.5rem;
$poly-gap-1000: 5rem;
$poly-gap-1500: 7.5rem;
$poly-margin-000: 0rem;
$poly-margin-025: 0.125rem;
$poly-margin-050: 0.25rem;
$poly-margin-075: 0.375rem;
$poly-margin-100: 0.5rem;
$poly-margin-150: 0.75rem;
$poly-margin-200: 1rem;
$poly-margin-250: 1.25rem;
$poly-margin-300: 1.5rem;
$poly-margin-400: 2rem;
$poly-margin-500: 2.5rem;
$poly-margin-600: 3rem;
$poly-margin-700: 3.5rem;
$poly-margin-800: 4rem;
$poly-margin-900: 4.5rem;
$poly-margin-1000: 5rem;
$poly-margin-1500: 7.5rem;
$poly-border-300: 0.1875rem;
$poly-border-400: 0.25rem;
$poly-corner-radius-075: 0.375rem;
$poly-colors-opacity-secondary-100: rgba(#222222, 1);
$poly-colors-opacity-secondary-90: rgba(#222222, 0.90);
$poly-colors-opacity-secondary-71: rgba(#222222, 0.71);
$poly-colors-opacity-secondary-45: rgba(#222222, 0.45);
$poly-colors-fade-fade-25: #222222;
$poly-colors-opacity-white-85: rgba(#ffffff, 0.85);
$poly-colors-opacity-white-100: rgba(#ffffff, 1);
$poly-colors-opacity-white-45: rgba(#ffffff, 0.45);
$poly-colors-opacity-white-60: rgba(#ffffff, 0.60);
$poly-colors-opacity-white-71: rgba(#ffffff, 0.71);
$poly-colors-opacity-white-white-always: #ffffff;
$poly-colors-opacity-secondary-black-always: #222222;
$poly-colors-opacity-secondary-50: rgba(#222222, 0.50);
$poly-colors-opacity-secondary-30: rgba(#222222, 0.30);
