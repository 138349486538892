.loading-modal {
  h1, h2, h3,  p {
    margin: 0
  }

  p {
    font-weight: 500;
  }

  .modal-body {
    text-align: center;
    padding: 40px;
  }

  .loader svg {
    height: auto;
    width: 77px;
    display: block;
    margin-bottom: 16px!important;
  }
}