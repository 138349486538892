.badge-element {
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: $secondary;
  color: white;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: 900;
  flex: none;

  &, &:hover {
    text-decoration: none;
  }
}

.badge-element.clickable,
a.badge-element {
  &:hover {
    background-color: rgba($secondary, 0.7);
  }
}