.participant-item {
  display: flex;
  align-items: center;
  background: #F4F4F4;
  padding: 0.5rem;
  padding-inline-end: 1rem;
  margin-bottom: 8px;

  p {
    margin: 0;
    @extend .text-truncate;
  }

  .avatar {
    width: 40px;
    height: 40px;
    margin-inline-end: 1rem;
  }

  .meta {
    flex: 1;
    overflow: hidden;

    .title {
      font-weight: 500;
    }

    .description {
      color: #888B8D;
      font-size: 14px;
    }
  }

  .checkbox {
    margin-inline-start: 1rem;

    .custom-checkbox {
      margin: 0.5rem 0 0 0;
    }
  }

  .status {
    flex: none;
    padding: 0 0.25rem;
    color: #63666A;
    font-weight: 500;

    .icon,
    .icon-start,
    .icon-end {
      &,svg {
        width: 24px;
        height: 24px;
      }
    }

    .icon, 
    .icon-start {
      margin-inline-end: 0.5rem;
    }

    .icon-end {
      margin-inline-start: 0.5rem;
    }


    .icon, span {
      vertical-align: middle;
    }

    &.small {
      font-size: 14px;
    }

    &,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.clickable {
    cursor: pointer;


  }

  &.selected {
    background: rgba(202, 16, 11, 0.15);

    .checkbox {
      color: #CA001B;
    }
  }

  &.error {
    .meta .title,
    .meta .description,
    .checkbox {
      color: #CA001B;
    }
  }
}
