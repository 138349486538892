.ng-select.users-select, .ng-select.multi-select {
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        line-height: 1;
        min-height: 0;
        padding: 0.7rem;

        border-bottom: 1px solid #D8D8D8;

        &:last-child {
          border-bottom: 0;
        }

        .wrapper {
          @extend .d-flex;
          @extend .align-items-center;
        }

        .user-info, .option-info {
          flex: 1;
          position: relative;
          overflow: hidden;

          p {
            @extend .text-truncate;
            margin: 0;
            line-height: 1.4;

            &.title {
              font-weight: 500;
            }

            &.sub-text {
              font-size: 14px;
              @extend .text-muted;
            }
          }
        }

        .avatar,
        .status {
          flex: none;
        }

        .avatar {
          width: 40px;
          height: 40px;
          margin-inline-end: 1rem;
        }

        .status {
          .icon {
            &,svg {
              width: 16px;
              height: 16px;
            }

            margin-inline-end: 0.5rem;
          }

          .icon, span {
            vertical-align: middle;
          }

          &.small {
            font-size: 14px;
          }
        }
      }
    }
  }

  &.ng-select-as-filter {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          padding: 0.5rem;

          .avatar {
            width: 30px;
            height: 30px;
            margin-inline-end: 0.5rem;

            @include media-breakpoint-up(md) {
              
            }
          
            @include media-breakpoint-up(xl) {
              margin-inline-end: 0.75rem;
            }
          }
        }
      }
    }
  }
}
