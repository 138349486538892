.badged-icon {
  display: inline-block;
  margin-inline-end: 1.5rem;
  position: relative;

  .icon {
    width: 28px;
    height: 28px;
  }

  .badge {
    position: absolute;
    min-width: 20px;
    display: block;
    height: 20px;
    padding: 0;
    line-height: 20px;
    text-align: center;
    border-radius: 10px;
    background: $secondary;
    color: white;
    transform: translate(-50%, -50%);
    top: 25%;
    font-weight: 900;
    font-size: 14px;
  }
}

//
// LTR
//

html:not([dir=rtl]) {
  .badged-icon {
    .badge {
      left: 100%;
    }
  }
}

//
// RTL
//

html:not([dir=rtl]) {
  .badged-icon {
    .badge {
      right: 100%;
    }
  }
}