.label-value-pairs {
  .pair {
    margin-bottom: 0.9rem;

    label,
    p {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}