.icon-steps {
  padding: 0;
  list-style-type: none;

  li {
    position: relative;

    .head {
      padding: 12px 0;

      .icon {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        vertical-align: middle;
        svg {
          display: block;
        }

        padding: 8px;
        background:  #C8D7FF;
        color: $primary;
      }

      .label {
        display: inline-block;
        vertical-align: middle;
        margin-left: 1rem;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .children {
      list-style-type: none;
      
      li {
        position: relative;
        padding: 8px 0 8px 44px;
        font-size: 14px;
        font-weight: 500;

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 100%;
          border: 2px solid $secondary;
          background: white;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &.active {
          color: $primary;
          font-weight: 900;

          &:before {
            background: $secondary;
          }
        }
      }
    }

    &:before {
      content: '';
      width: 2px;
      background: $secondary;
      position: absolute;
      left: 19px;
      top: 0;
      bottom: 0;
    }

    &:first-child {
      &:before {
        top: 12px;
      }
    }

    &:last-child {
      &:before {
        bottom: 12px;
      }
    }
  }

  li.active .head,
  li .head.active {
    .icon {
      background: $primary;
      color: white;
    }

    .label {
      color: $primary;
      font-weight: 900;
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .icon-steps {
    li {
      .head {
        .label {
          margin-left: 0;
          margin-right: 1rem;
        }
      }

      .children {
        li {
          padding: 8px 45px 8px 0;

          &:before {
            left: auto;
            right: 0;
          }
        }
      }

      &:before {
        left: auto;
        right: 19px;
      }
    }
  }
}