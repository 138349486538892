.btn {
  &.btn-link {
    color: $secondary;
  } 

  svg {
    display: inline-block!important;
  }

  &.loader {
    position: relative;

    span {
      opacity: 0;
    }

    svg,
    span.loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      opacity: 1;
    }

    span.loader {
      line-height: 1;
      color: white;
    }
  }

  &.btn-circle {
    @extend .rounded-circle;
    padding-left: 0;
    padding-right: 0;
    min-width: 40px;
  }

  &.with-badge {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      background: #CA001B;
      border-radius: 100%;
      top: -6px;
    }
  }

  &.disabled,
  &:disabled {
    opacity: 1;
    background-color: #D8D8D8;
    border-color: #D8D8D8;
    pointer-events: none;
  }
}

.btn-link {
  font-weight: $btn-font-weight;
}

.btn-muted {
  @extend .btn-light;
  background: #D8D8D8;
  border-color: #D8D8D8;
}

.btn-settings {
  @extend .btn-muted;

  padding-left: 0.5rem;
  padding-right: 0.5rem;
  min-width: 0!important;
  
  &.dropdown-toggle {
    &:after {
      content: '';
      display: none;
    }
  }

  &.btn-sm {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

.btn-passive {
  @include button-variant(#C8D7FF, #C8D7FF, #DAE4FF, #DAE4FF, #DAE4FF, #DAE4FF);
  color: $primary;
}

.btn-cookie-settings,
.btn-input-style {
  @extend .btn-light;
  background: $input-bg;
  
  &,&:hover,&:focus {
    border-color: transparent;
  }
}

.btn-group {
  .btn {
    min-width: 42px;

    &.btn-sm {
      font-size: 0.875rem;
    }
  }
}

//
// LTR
//

html:not([dir=rtl]) {
  .btn {
    &.with-badge {
      &:after {
        right: -6px;
      }
    }
  }
}


//
// RTL
//

html[dir=rtl] {
  .btn {
    &.with-badge {
      &:after {
        left: -6px;
      }
    }
  }
}