.inline-page-notification {
  @extend .body-content;
  @extend .text-info;

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background: linear-gradient(0deg, rgba(199, 26, 131, 0.1), rgba(199, 26, 131, 0.1)), #FFFFFF;

  .notification-icon {
    svg {
      width: 32px;
      height: 32px;
    }
  }

  p {
    margin: 3px 0;
    font-size: 0.875rem;

    &.title {
      font-weight: 900;
      font-size: 1.125rem;
    }
  }
}