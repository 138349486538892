$flow-layout-sidebar-width-sm: 420px;
$flow-layout-sidebar-width-md: 445px;
$flow-layout-sidebar-width-lg: 470px;
$flow-layout-sidebar-width-xl: 480px;

.flow-layout {
  position: relative;
  padding-inline-start: $flow-layout-sidebar-width-sm;

  @include media-breakpoint-up(md) {
    padding-inline-start: $flow-layout-sidebar-width-md;
  }

  @include media-breakpoint-up(lg) {
    padding-inline-start: $flow-layout-sidebar-width-lg;
  }

  @include media-breakpoint-up(xl) {
    padding-inline-start: $flow-layout-sidebar-width-xl;
  }

  .layout-sidebar {
    position: fixed;
    height: 100%;
    background: #F4F4F4;
    top: 0; 
    left: 0;
    right: auto;
    display: flex;
    flex-direction: column;
    z-index: 100;

    width: $flow-layout-sidebar-width-sm;

    @include media-breakpoint-up(md) {
      width: $flow-layout-sidebar-width-md;
    }

    @include media-breakpoint-up(lg) {
      width: $flow-layout-sidebar-width-lg;
    }

    @include media-breakpoint-up(xl) {
      width: $flow-layout-sidebar-width-xl;
    }
  }

  .layout-body {
    min-height: 100vh;
    background-color: white;

    &.with-background {
      position: relative;
      overflow: hidden;

      background: rgb(235,23,0);
      background: -moz-linear-gradient(135deg, rgba(235,23,0,1) 15%, rgba(133,13,0,1) 100%);
      background: -webkit-linear-gradient(135deg, rgba(235,23,0,1) 15%, rgba(133,13,0,1) 100%);
      background: linear-gradient(135deg, rgba(235,23,0,1) 15%, rgba(133,13,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eb1700",endColorstr="#850d00",GradientType=1);

      background-size: 100% 100%;

      .layout-background {
        &:after {
          content: '';
          display: block;
          padding-top: 112.63%; //aspect-ratio of the svg
        }

        pointer-events: none;
        display: block;
        position: absolute;
        top: 88%;
        left: 58%;
        transform: translate(-50%, -50%);
        width: 130%;
        max-width: 1800px;

        @include media-breakpoint-up(xl) {
          top: 78%;
          width: 150%;
        }

        background-image: url("src/assets/svg-color/body-shapes-hcp.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }

    &.fixed {
      position: fixed;
      min-height: 0;
      top: 0;
      bottom: 0;
      right: 0;
      left: $flow-layout-sidebar-width-sm;
    
      @include media-breakpoint-up(md) {
        left: $flow-layout-sidebar-width-md;
      }
  
      @include media-breakpoint-up(lg) {
        left: $flow-layout-sidebar-width-lg;
      }
  
      @include media-breakpoint-up(xl) {
        left: $flow-layout-sidebar-width-xl;
      }
    }
  }

  .layout-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: $flow-layout-sidebar-width-sm;
    background: white;
    box-shadow: 0 0 36px rgba(136, 136, 141, 0.3);

    @include media-breakpoint-up(md) {
      left: $flow-layout-sidebar-width-md;
    }

    @include media-breakpoint-up(lg) {
      left: $flow-layout-sidebar-width-lg;
    }

    @include media-breakpoint-up(xl) {
      left: $flow-layout-sidebar-width-xl;
    }
  }

  .layout-footer,
  .layout-footer-pusher {
    height: 64px;
  }
}

// 
// RTL
// 

html[dir=rtl] {
  .flow-layout {
    .layout-sidebar {
      left: auto;
      right: 0;
    }

    .layout-body.fixed,
    .layout-footer {
      left: 0;
      right: $flow-layout-sidebar-width-sm;
    
      @include media-breakpoint-up(md) {
        right: $flow-layout-sidebar-width-md;
      }
  
      @include media-breakpoint-up(lg) {
        right: $flow-layout-sidebar-width-lg;
      }
  
      @include media-breakpoint-up(xl) {
        right: $flow-layout-sidebar-width-xl;
      }
    }
  }
}
