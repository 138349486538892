.table.admin-table {
  margin-bottom: 4px;

  th,
  td {
    border-top: none;

    &.center {
      text-align: center;
    }
  }

  thead {
    background-color: #ebebeb;
    color: #888b8d;
    

    th {
      border-bottom: none;
      font-weight: 300;
      font-size: 14px;
    }

    padding-bottom: 4px;
  }

  tbody {
    &:before {
      content: "@";
      display: block;
      line-height: 4px;
      text-indent: -99999px;
    }

    tr {
      background-color: white;
      border-bottom: 4px #f4f4f4 solid;
    }

    td,
    th {
      vertical-align: middle;
    }

    td,
    th {
      &.icon-col {
        width: 48px;
        text-align: center;
      }
    }

    td.arrow {
      width: 24px;
    }

    td.order {
      width: 48px;
      text-align: center;
    }

    td.remove {
     text-align: right; 
    }

    td.action {
      padding: 0;
      flex: 1;
      width: 48px;

      .action-button {
        background-color: #ca001b;
        color: white;
        min-height: 52px;
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.secondary {
          background-color: #D8D8D8;
        }
      }
    }
  }

  strong {
    font-weight: 700;
  }
}
