.body-content {
  padding: 24px;

  @include media-breakpoint-up(md) {
    padding: 32px;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px;
  }

  &.lg {
    padding: 38px 24px;

    @include media-breakpoint-up(md) {
      padding: 50px 32px;
    }

    @include media-breakpoint-up(lg) {
      padding: 64px 40px;
    }    
  }
}