.avatar {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #eaeaea;
  border-radius: 100%;
  background-size: cover;
  background-position: center center;

  .icon {
    position: absolute;
    top: 21%;
    left: 21%;
    width: 58%;
    padding-top: 58%;
    color: $primary;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.empty {
    background-color: #C8D7FF;
    background-image: url('../../assets/images/default-avatar.png')!important;
  }
}
