$default-layout-header-back-filters-height: 166px;
$default-layout-header-back-filters-height-md: 180px;
$default-layout-header-back-filters-height-lg: 194px;

.default-layout {
  .layout-header.header-back-filters,
  .layout-header-pusher.header-back-filters {
    box-sizing: content-box;
    height: $default-layout-header-back-filters-height;

    @include media-breakpoint-up(md) {
      height: $default-layout-header-back-filters-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $default-layout-header-back-filters-height-lg;
    }
  }
}